import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import Modal from 'react-modal';
const ExcelUploadPage = () => {
  const [recordLimit, setRecordLimit] = useState(null);
  const [fileRowCount, setFileRowCount] = useState(null);
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [duplicateData, setDuplicateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const fileInputRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);  // State for modal visibility
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isInserted, setIsInserted] = useState(false); 


  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (fileExtension !== 'xlsx' && fileExtension !== 'xls') {
        setError('Please upload a valid Excel file.');
        setFile(null);
        return;
      }
      setError('');
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setFileRowCount(jsonData.length);

        if (recordLimit && jsonData.length > recordLimit) {
          setError(`The file exceeds the record limit of ${recordLimit} rows.`);
          setFileData(null);
        } else {
          setFileData(jsonData);
        }
      };
      reader.readAsArrayBuffer(selectedFile);
    }
  };

  const handleModalOpen = (message) => {
    setConfirmationMessage(message);
    setModalIsOpen(true);
  };

  const handleProceed = async () => {
    setModalIsOpen(false);
    insertData();  // Proceed with the insertion logic
  };

  const handleCancel = () => {
    setModalIsOpen(false);  // Close modal on cancel
  };
  const handleLimitChange = (e) => {
    setRecordLimit(Number(e.target.value));
    setFileData(null);
    setError('');
  };

  const handleSubmit = async () => {
    if (!recordLimit) {
      setError('Please select a record limit.');
      return;
    }

    if (!file) {
      setError('Please upload an Excel file.');
      return;
    }

    if (fileRowCount > recordLimit) {
      setError(`You cannot upload more than ${recordLimit} records.`);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('excel_file', file);

    try {
      const response = await fetch(SummaryApi.verificationDuplicatesData.url, {
        method: SummaryApi.verificationDuplicatesData.method,
        headers: {
          'Authorization': 'Bearer sandeep_university_916',
        },
        body: formData,
      });

      const data = await response.json();
      if (data.status) {
        setDuplicateData(data.duplicates.data);
        if (data.duplicates.count > 0) {
          const newWindow = window.open('', '_blank');
          newWindow.document.write('<html><head><title>Duplicate Data</title></head><body>');
      
          // Load Tailwind CSS and Font Awesome for icons
          newWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">');
          newWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />');
      
          newWindow.document.write(`
            <div class="p-5 relative">
              <!-- Top-right total duplicates count -->
              <div class="absolute top-5 right-5 bg-blue-500 text-white rounded-full px-4 py-2 text-sm font-bold shadow-lg">
                Total Duplicates: ${data.duplicates.count}
              </div>
      
              <!-- Download Button -->
              <div class="mb-5">
                <button 
                  class="flex items-center bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg text-lg"
                  onclick="handleDownloadExcel()"
                >
                  <i class="fas fa-download mr-2"></i> Download Excel
                </button>
              </div>
      
              <h1 class="text-2xl font-bold mb-4 text-center">Duplicate Data</h1>
      
              <table class="table-auto w-full border border-collapse border-gray-200">
                <thead>
                  <tr class="bg-gray-100">
                    <th class="border border-gray-300 px-4 py-2 text-center">SNO</th>
                    <th class="border border-gray-300 px-4 py-2 text-center">Mobile Number</th>
                    <th class="border border-gray-300 px-4 py-2 text-center">Student Name</th>
                    <th class="border border-gray-300 px-4 py-2 text-center">College</th>
                    <th class="border border-gray-300 px-4 py-2 text-center">Course</th>
                    <th class="border border-gray-300 px-4 py-2 text-center">Year</th>
                  </tr>
                </thead>
                <tbody>
          `);
      
          // Add rows for the table
          data.duplicates.data.forEach((row, index) => {
            newWindow.document.write(`
              <tr>
                <td class="border border-gray-300 px-4 py-2 text-center">${index + 1}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">${row.mobile_number}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">${row.student_name}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">${row.clg_name}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">${row.course}</td>
                <td class="border border-gray-300 px-4 py-2 text-center">${row.year}</td>
              </tr>
            `);
          });
      
          newWindow.document.write(`
                </tbody>
              </table>
            </div>
          `);
          // Embed the handleDownloadExcel function inside the new tab context
          newWindow.document.write(`
            <script src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.full.min.js"></script>
            <script>
              const duplicateData = ${JSON.stringify(data.duplicates.data)}; // Pass data as a variable inside the new tab context
              
              function handleDownloadExcel() {
                if (!duplicateData || duplicateData.length === 0) {
                  console.error("No duplicate data available to export.");
                  return;
                }
      
                const sheet = XLSX.utils.json_to_sheet(duplicateData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, sheet, "Duplicate Data");
      
                const excelFile = XLSX.write(workbook, {
                  bookType: 'xlsx',
                  type: 'array'
                });
      
                const blob = new Blob([excelFile], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
      
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'duplicate_student_data.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            </script>
          `);
      
          newWindow.document.write('</body></html>');
          setTimeout(() => {
            handleModalOpen(`There are ${data.duplicates.count} duplicates. Proceed with data insertion, skipping duplicates and inserting only unique entries?`, 'path/to/excel');
          }, 1000);
        } else {
          insertData();
        }
      } else {
        setError(data.message || 'Error verifying file. Please try again.');
      }
    } catch (error) {
      setError('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const insertData = async () => {
    try {
      const formData = new FormData();
      formData.append('excel_file', file);
  
      const response = await fetch(SummaryApi.excelUpload.url, {
        method: SummaryApi.excelUpload.method,
        headers: {
          'Authorization': 'Bearer sandeep_university_916',
        },
        body: formData,
      });
  
      const data = await response.json();
      if (data.status) {
        toast.success(data.message); // Display the message from the backend
        setIsInserted(true); // Set insertion success to true
      } else {
        toast.error('Error inserting data.');
      }
    } catch (error) {
      toast.error('Error inserting data.');
    }
  };
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const currentPageData = duplicateData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-10">
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold text-center text-gray-700 mb-6">Upload Excel File</h1>
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={handleCancel}
  contentLabel="Duplicate Data Confirmation"
  className="w-full max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg"
  overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
>
  <h2 className="text-xl mb-4">{confirmationMessage}</h2>
  <div className="flex justify-between">
    <button onClick={handleProceed} className="bg-blue-600 text-white py-2 px-4 rounded-lg">Proceed</button>
    <button onClick={handleCancel} className="bg-red-400 text-white py-2 px-4 rounded-lg">Cancel</button>
  </div>
</Modal>

        <div className="mb-4">

          <label className="block text-sm font-medium text-gray-600">Select Record Limit</label>
          <select
            className="mt-2 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            onChange={handleLimitChange}
            value={recordLimit || ''}>
            <option value="">Select Limit</option>
            <option value={10000}>10,000</option>
            <option value={20000}>20,000</option>
            <option value={30000}>30,000</option>
            <option value={50000}>50,000</option>
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-600">Upload Excel File</label>
          <input
            ref={fileInputRef}
            type="file"
            accept=".xlsx,.xls"
            className="mt-2 block w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            onChange={handleFileChange}
          />
        </div>

        {fileRowCount && (
          <div className='font-bold text-lg mb-4'>Records Count: 
            <span className='text-green-700 ml-2'>{fileRowCount}</span>
          </div>
        )}

        {fileData && (
          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-700">Preview (first 5 records)</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse border border-gray-300">
                <thead className="bg-blue-600 text-white">
                  <tr>
                    {Object.keys(fileData[0]).map((key) => (
                      <th key={key} className="px-4 py-2 border-b text-left text-sm font-medium">
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fileData.slice(0, 5).map((row, index) => (
                    <tr key={index} className="bg-white">
                      {Object.keys(row).map((key) => (
                        <td key={key} className="px-4 py-2 border-b text-sm text-gray-700">
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

        <button
  onClick={handleSubmit}
  className={`w-full py-2 px-4 rounded-lg text-white ${loading || isInserted ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
  disabled={loading || isInserted} // Disable button if loading or data is inserted
>
  {loading ? 'Uploading...' : 'Upload Data'}
</button>

      </div>
    </div>
  );
};

export default ExcelUploadPage;
