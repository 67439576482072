import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getStudentsData } from '../components/Studentsdata'; // Import the data file
import { AiOutlineClose } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle} from 'react-icons/fa';
import { IoIosCloseCircle } from "react-icons/io";

import SummaryApi from '../common';
import { toast } from 'react-toastify';

Modal.setAppElement('#root'); // Required for accessibility, ensure this matches your root element

const AssignedStudents = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStudent, setCurrentStudent] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [studentsData, setStudentsData] = useState([]); // State to store the students data
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [error, setError] = useState(null); // State to handle errors
  const [todayTaskfollowedUpCount, setTodayTaskFollowedUpCount] = useState(0);
  const [previousTaskfollowedUpCount, setPreviousTaskFollowedUpCount] = useState(0);
  const [taskTodayNotFollowedUpCount, setTaskTodayNotFollowedUpCount] = useState(0);
  const [taskPreviousNotFollowedUpCount, setTaskPreviousNotFollowedUpCount] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState([]); // Stores selected courses
  const [dropdownValue, setDropdownValue] = useState(''); // Value of selected option
  const [timer, setTimer] = useState('00:00:00');
const [intervalId, setIntervalId] = useState(null);
const [inTime, setInTime] = useState('');
const [outTime, setOutTime] = useState('');


  // Courses based on UG/PG selection
  const ugCourses = ['CSE', 'CSE(AI & ML)', 'CSE(AI & DS)', 'AERONAUTICAL ENGINERRING', 'ECE'];
  const pgCourses = [];



useEffect(() => {
  if (modalVisible) {
    let seconds = 0;

    const id = setInterval(() => {
      seconds++;
      const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
      const secs = String(seconds % 60).padStart(2, '0');
      setTimer(`${hours}:${minutes}:${secs}`);
    }, 1000);

    setIntervalId(id);
  } else {
    clearInterval(intervalId);
    setTimer('00:00:00'); // Reset timer when modal closes
  }

  return () => clearInterval(intervalId);
}, [modalVisible]);

const getFormattedISTTime = () => {
  const now = new Date();

  // IST Offset in milliseconds (UTC +5:30)
  const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes in milliseconds
  const istTime = new Date(now.getTime() + istOffset);

  // Extract year, month, day, hours, minutes, and seconds
  const year = istTime.getUTCFullYear();
  const month = String(istTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(istTime.getUTCDate()).padStart(2, '0');
  const hours = String(istTime.getUTCHours()).padStart(2, '0'); // Use UTC hours
  const minutes = String(istTime.getUTCMinutes()).padStart(2, '0'); // Use UTC minutes
  const seconds = String(istTime.getUTCSeconds()).padStart(2, '0'); // Use UTC seconds

  // Return the date and time in yyyy-mm-dd hh:mm:ss format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


  const getCurrentDate = () => {
    // Get the current date in YYYY-MM-DD format
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading state to true
      const data = await getStudentsData(); // Fetch data using the employee_id from sessionStorage
      setStudentsData(data); // Set the fetched data to state

      const today = getCurrentDate();

      // Initialize counters
      let todayTaskFollowedUp = 0;
      let previousTaskFollowedUp = 0;
      let todayNotFollowedUp = 0;
      let previousNotFollowedUp = 0;

      data.forEach(student => {
        // Extract the date part of created_date (ignore the time)
        const createdDate = student.created_date.split(' ')[0]; // Only take the date part (YYYY-MM-DD)

        // Count followed-up students (is_follow_up === '1')
        if (createdDate === today && student.is_follow_up === '1') {
          todayTaskFollowedUp++;
        }

        if (createdDate < today && student.is_follow_up === '1') {
          previousTaskFollowedUp++;
        }

        // Task Date Today - Count students with task_date today who have not followed up
        if (createdDate === today && student.is_follow_up === '0') {
          todayNotFollowedUp++;
        }

        // Task Previous - Count students with task_date before today who have not followed up
        if (createdDate < today && student.is_follow_up === '0') {
          previousNotFollowedUp++;
        }
      });

      // Set the counts to state
      setTodayTaskFollowedUpCount(todayTaskFollowedUp);
      setPreviousTaskFollowedUpCount(previousTaskFollowedUp);
      setTaskTodayNotFollowedUpCount(todayNotFollowedUp);
      setTaskPreviousNotFollowedUpCount(previousNotFollowedUp);
    } catch (error) {
      console.error('Error fetching student data:', error);
      setError('Failed to fetch student data');
    } finally {
      setLoading(false); // Once data is fetched, set loading to false
    }
  };


  const validateForm = () => {
    const { call_status, is_interested, call_back_reason, other_reasons } = formData;
  
    if (call_status === 'Contacted') {
      if (!['Not Interested', 'Interested', 'Most Interested', 'Call Back', 'Others'].includes(is_interested)) {
        toast.warning('Please select an option: Not Interested, Interested, Most Interested, Call Back, or Others.');
        return false;
      }
  
      if (is_interested === 'Call Back' && !call_back_reason) {
        toast.warning('Please select a Call Back reason.');
        return false;
      }
  
      if (is_interested === 'Others' && !other_reasons) {
        toast.warning('Please select an Other reason.');
        return false;
      }
    }
  
    return true; // All validations passed
  };
  
  useEffect(() => {
    fetchData(); // Call the fetchData function on component mount
  }, []);

  const [formData, setFormData] = useState({
    student_name: '',
    employee_student_id: '',
    employee_id: '',
    gender: 'Male',
    father_name: '',
    mobile_number1: '',
    mobile_number2: '',
    email: '',
    aadhar_number: '',
    state: '',
    district: '',
    city: '',
    address:'',
    code: '',
    pincode: '',
    clg_name: '',
    course: '',
    ug_pg_course: '',
    year: '',
    mobile_number: '',
    is_follow_up: false,
    program_type: 'Full-time',
    is_interested: '',
    call_back_reason: '',
    other_reasons: '',
    call_status: '',
    call_duration: '00:00:00.000000',
    remarks: '',
    is_active: false,
    created_date: '', // Optional field
    follow_up_date: '',
  });

  const formatDate = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      return "NA";
    }

    // Options for formatting the date
    const options = { day: '2-digit', month: 'short', year: 'numeric' };

    // Return the formatted date string
    return date.toLocaleDateString('en-GB', options);
  };

  useEffect(() => {
    if (modalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalVisible]);
  
  useEffect(() => {
    if (formData.call_duration) {
      // Split the call_duration at the dot to remove milliseconds
      const [timePart] = formData.call_duration.split('.'); 
      const [hours, minutes, seconds] = timePart.split(':').map(part => part.padStart(2, '0')); 
  
      setFormData((prevData) => ({
        ...prevData,
        hours,     // Set hours
        minutes,   // Set minutes
        seconds,   // Set seconds
      }));
    }
  }, [formData.call_duration]);

  
  
  const columns = React.useMemo(
    () => [
      { Header: 'SNO', accessor: 'sno' },
      { Header: 'Student Name', accessor: 'student_name' },
      { Header: 'College Name', accessor: 'clg_name' },
      { Header: 'Course', accessor: 'course' },
      { Header: 'Year', accessor: 'year' },
      { Header: 'Mobile Number', accessor: 'mobile_number' },
      { Header: 'District', accessor: 'district' },
      { Header: 'City', accessor: 'city' },
      // { Header: 'Address', accessor: 'address' },
      { Header: 'Code', accessor: 'code' },
      {
        Header: 'Task Date', accessor: 'task_date',
      },
      {
        Header: 'Follow Up Date',
        accessor: 'follow_date',
        Cell: ({ row }) => {
          const { follow_up_date, is_follow_up } = row.original;

          // Check if is_follow_up is "0" (Not followed up yet)
          if (is_follow_up === "0") {
            return <span>Not followed up yet</span>; // Custom message
          }

          // Otherwise, format and display the actual follow_up_date
          return <span>{formatDate(follow_up_date)}</span>;
        },
      },
      {
        Header: 'Actions',
        accessor: 'edit',
        Cell: ({ row }) => (
          <button
            onClick={() => handleOpenModal(row)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Enroll
          </button>
        ),
      },
    ],
    []
  );


  // Assuming studentsData is the array containing your student records
  const updatedStudentsData = studentsData.map((student, index) => ({
    ...student, // Copy the original student object
    sno: index + 1, // Assign the serial number as index + 1
  }));
{/* Call Duration Change Handler */}
// const handleChangeCallDuration = (e) => {
//   const value = Math.max(0, Math.min(e.target.value, 30)); // Ensure the value is between 0 and 30
//   setFormData({
//     ...formData,
//     call_duration: value, // Update call_duration in formData
    
//   });
// };
  const pageCount = Math.ceil(studentsData.length / pageSize);
  const displayStart = pageIndex * pageSize + 1;
  const displayEnd = Math.min((pageIndex + 1) * pageSize, studentsData.length);
  const currentData = updatedStudentsData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const handleOpenModal = (student = null) => {
    const currentDateTime = getFormattedISTTime(); // System time in IST for in_time
    setInTime(currentDateTime); 
    if (student) {
      // Ensure call_duration exists and split to remove milliseconds (if it exists)
      const callDuration = student.call_duration || '00:00:00.000000'; // Default if undefined or null
      const [hours, minutes, seconds] = callDuration.split('.')[0].split(':').map(part => part.padStart(2, '0'));
  
      setCurrentStudent(student);
      setFormData({
        student_name: student.student_name || '',
        employee_student_id: student.employee_student_id || '',
        employee_id: student.employee_id || '',
        district: student.district || '',
        city: student.city || '',
        address: student.address || '',
        call_duration: student.call_duration || '00:00:00.000000', // Store the full call_duration including milliseconds
        code: student.code || '',
        clg_name: student.clg_name || '',
        course: student.course || '',
        year: student.year || '',
        mobile_number1: student.mobile_number || '',
        call_status: student.call_status || '',
        call_back_reason: student.call_status || '',
        other_reasons: '',
        is_follow_up: student.is_follow_up || false,
        is_active: student.is_active || false,
        created_date: student.created_date || '', // Optional field
        follow_up_date: student.follow_up_date || '',
        hours,       // Set the hours separately
        minutes,     // Set the minutes separately
        seconds,     // Set the seconds separately
      });
    } else {
      setFormData({
        student_name: '',
        employee_student_id: '',
        employee_id: '',
        district: '',
        city: '',
        address: '',
        call_duration: '00:00:00.000000', // Default to no call duration
        code: '',
        clg_name: '',
        course: '',
        year: '',
        mobile_number1: '',
        call_status: '',
        call_back_reason: '',
        other_reasons: '',
        is_follow_up: false,
        is_active: false,
        created_date: '', // Optional field
        follow_up_date: '',
        hours: '00',       // Default to 00 hours
        minutes: '00',     // Default to 00 minutes
        seconds: '00',     // Default to 00 seconds
      });
    }
    setModalVisible(true);
  };


  const handleCloseModal = async () => {
    const currentDateTime = getFormattedISTTime(); // System time in IST for out_time
    setOutTime(currentDateTime); // Set the `out_time` 
    // Reset modal and state after API call
    setModalVisible(false);
    setCurrentStudent(null);
    setSelectedCourses([]);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // If call_status is 'Not Answered', reset is_interested field
    if (name === 'call_status' && (value === 'Not Answered' || value === '')) {
      setFormData((prev) => ({
        ...prev,
        is_interested: '', // Clears the value of is_interested
        [name]: value, // Update the call_status
      }));
    } 
    // If 'is_interested' is changed
    else if (name === 'is_interested') {
      // If value is not 'Call Back', clear call_back_reason
      // If value is not 'Others', clear other_reasons
      setFormData((prev) => ({
        ...prev,
        [name]: value, // Update the is_interested value
        ...(value !== 'Call Back' && { call_back_reason: '' }), // Clear call_back_reason if not 'Call Back'
        ...(value !== 'Others' && { other_reasons: '' }), // Clear other_reasons if not 'Others'
      }));
    } else if (name === 'program_type' && (value === 'PG' || value === '')) {
      setSelectedCourses([]);
      setDropdownValue(''); 
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
      }));
    } 
    // For other inputs (e.g., checkboxes, radio buttons), just update their values
    else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
      }));
    }
  };  

  // Handle dropdown selection
  const handleSelectChange = (e) => {
    const selectedCourse = e.target.value;
    if (selectedCourse && !selectedCourses.includes(selectedCourse)) {
      setSelectedCourses((prev) => [...prev, selectedCourse]); // Add selected course
    }
    setDropdownValue(''); // Clear the dropdown after selection
  };

  // Remove a course tag
  const handleRemoveTag = (course) => {
    setSelectedCourses((prev) => prev.filter(c => c !== course));
  };

  // Filter the courses based on UG/PG selection
  const courses = formData.program_type === 'UG' ? ugCourses : formData.program_type === 'PG' ? pgCourses : [];

  const headers = {
    'Authorization': 'Bearer sandeep_university_916',
    'Content-Type': 'application/json',
  };


  const handleSave = async (e) => {
    setLoadingSubmit(true);
    e.preventDefault();
    
    if (validateForm()) {
      // Proceed with form submission logic
      console.log('Form submitted successfully!');
    }
    else{
      setLoadingSubmit(false);
      return
    }
    try {
      const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
      if (!encodedEmployeeId) {
        throw new Error('Employee ID not found in session storage');
      }
      const decodedEmployeeId = atob(encodedEmployeeId); // Decode the employee ID

      // Construct the payload to send to the API
      const payload = {
        employee_student_id: formData.employee_student_id || '',
        employee_id: decodedEmployeeId || '',
        student_name: formData.student_name || '',
        last_name: '',
        gender: formData.gender || '',
        father_name: formData.father_name || '',
        mobile_number1: formData.mobile_number1 || '',
        mobile_number2: formData.mobile_number2 || '', // Ensure an empty string if not provided
        email: formData.email || '',
        aadhar_number: formData.aadhar_number || '',
        state: formData.state || '',
        district: formData.district || '',
        city: formData.city || '',
        address:formData.address || '',
        call_duration: timer, 
        pincode: formData.pincode || '',  // Assuming 'code' is pincode
        course: formData.course || '',
        clg_name: formData.clg_name || '',
        inter_percentage: formData.inter_percentage || '',
        program_type: formData.program_type || '',
        ug_pg_course: selectedCourses.join(', '),
        call_status: formData.call_status || '', // Default to empty string if not provided
        remarks: formData.remarks || '', // Default to empty string if not provided
        is_interested: formData.is_interested === "Others" ? formData.other_reasons : formData.is_interested,
        call_back_reason: formData.call_back_reason || '',
      };

      // Make the API call to save the student
      const response = await fetch(SummaryApi.uploadStudent.url, {
        method: SummaryApi.uploadStudent.method,
        headers: headers,
        body: JSON.stringify(payload),  // Send the payload with the correct data
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status) {
        toast.success('Student added successfully!');

        // Correctly extract student_id from the response
        const studentId = result.student_id; // Updated this line
    
        // Now proceed to create the call log
        const currentDateTime = getFormattedISTTime(); // Get system time for out_time
        setOutTime(currentDateTime);
    
        const callLogPayload = {
          employee_id: formData.employee_id,
          student_id: studentId, // Use the correct studentId here
          in_time: inTime,
          out_time: currentDateTime,
          call_duration: timer,
          remarks: formData.remarks || "",
        };
    
        console.log("Call log payload:", callLogPayload); // Debugging line
    
        const callLogResponse = await fetch(SummaryApi.calllogsstudentcreate.url, {
          method: SummaryApi.calllogsstudentcreate.method,
          headers: {
            'Authorization': 'Bearer sandeep_university_916',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(callLogPayload),
        });
    
        const callLogResult = await callLogResponse.json();
        if (callLogResult.status) {
        } else {
          toast.error('Failed to create call log.');
        }
        handleCloseModal();
        fetchData(); // Fetch updated data after success
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error('Error during save process:', error);
      // Optionally display a toast error
    } finally {
      setLoadingSubmit(false); // Reset loading state when done
    }};

  return (
    <div className="container mx-auto p-4">
      {loadingSubmit && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Submitting...</span>
        </div>
      )}

      <h1 className='text-2xl font-bold mb-6 text-gray-800'>Today's Task</h1>

      {/* Summary Section */}
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6'>
        {/* Total Followed Up */}
        <div className='bg-green-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaCheckCircle className='text-green-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-green-700'>Today Task Followed Up</h3>
            <p className='text-xl font-bold text-green-800'>{todayTaskfollowedUpCount}</p>
          </div>
        </div>

        {/* Total Not Followed Up */}
        <div className='bg-yellow-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaCheckCircle className='text-yellow-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-yellow-700'>Previous Task Followed Up</h3>
            <p className='text-xl font-bold text-yellow-800'>{previousTaskfollowedUpCount}</p>
          </div>
        </div>

        {/* Today Not Followed Up */}
        <div className='bg-slate-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaTimesCircle className='text-slate-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-slate-700'>Today Not Followed Up</h3>
            <p className='text-xl font-bold text-slate-800'>{taskTodayNotFollowedUpCount}</p>
          </div>
        </div>

        {/* Previous Not Followed Up */}
        <div className='bg-red-100 p-4 rounded-lg shadow-lg flex items-center'>
          <FaTimesCircle className='text-red-600 text-3xl mr-4' />
          <div>
            <h3 className='text-lg font-semibold text-red-700'>Previous Not Followed Up</h3>
            <p className='text-xl font-bold text-red-800'>{taskPreviousNotFollowedUpCount}</p>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto p-4">
        <table className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-700">
            <tr>
              {columns.map((column) => (
                <th key={column.accessor} className="px-4 py-2 text-center text-gray-700 font-semibold">
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {/* Render loading skeleton if rows are still loading */}
            {loading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <tr key={index}>
                  <td colSpan={columns.length} className="text-center">
                    <div className="animate-pulse">
                      <div className="h-14 bg-gray-300 w-full px-4 py-2"></div>
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            // Render actual rows when data is fetched
            currentData.map((row, index) => {
              // Get the current system date
              const currentDate = getCurrentDate();

              // Get the row's created date (task date)
              const createdDate = row.created_date.split(' ')[0]; // Remove time part if present (i.e., YYYY-MM-DD)

              // Check the conditions to apply the color
              let rowColor = ''; // Default color
              let hoverColor = ''; // Default hover color

              // Condition 1: currentDate != createdDate and is_follow_up = 0 (Red)
              if (currentDate !== createdDate && row.is_follow_up === "0") {
                rowColor = 'bg-red-50'; // Light red background
                hoverColor = 'hover:bg-red-100'; // Red hover
              }
              // Condition 2: currentDate != createdDate and is_follow_up = 1 (Yellow)
              else if (currentDate !== createdDate && row.is_follow_up === "1") {
                rowColor = 'bg-yellow-50'; // Light yellow background
                hoverColor = 'hover:bg-yellow-100'; // Yellow hover
              }
              // Condition 3: currentDate == createdDate and is_follow_up = 1 (Green)
              else if (currentDate === createdDate && row.is_follow_up === "1") {
                rowColor = 'bg-green-100'; // Light green background
                hoverColor = 'hover:bg-green-200'; // Green hover
              }
              // Condition 4: currentDate == createdDate and is_follow_up = 0 (White)
              else if (currentDate === createdDate && row.is_follow_up === "0") {
                rowColor = 'bg-white'; // White background
                hoverColor = 'hover:bg-gray-50'; // Light gray hover
              }

              return (
                <tr key={index} className={`border-b ${hoverColor} ${rowColor}`}>
                  {columns.map((column) => (
                    <td key={column.accessor} className="border text-center px-4 py-2">
                      {column.accessor === 'edit' ? (
                        row.is_follow_up === "1" ? (
                          <span className="px-4 py-2 text-green-500 font-bold">
                            Enrolled
                          </span>
                        ) : (
                          <button
                            onClick={() => handleOpenModal(row)}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                          >
                            Enroll
                          </button>
                        )
                      ) : column.accessor === 'task_date' ? (
                        <span>{formatDate(row.created_date)}</span>
                      ) : column.accessor === 'follow_date' ? (
                        <span>{formatDate(row.follow_up_date, row.is_follow_up)}</span>
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <span className="text-gray-700 text-sm">
            Showing {displayStart} to {displayEnd} of {studentsData.length} entries
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={() => setPageIndex(0)}
            disabled={pageIndex === 0}
          >
            {'<<'}
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}
            disabled={pageIndex === 0}
          >
            {'<'}
          </button>
          <span>
            Page {pageIndex + 1} of {pageCount}
          </span>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={() => setPageIndex(Math.min(pageCount - 1, pageIndex + 1))}
            disabled={pageIndex === pageCount - 1}
          >
            {'>'}
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={() => setPageIndex(pageCount - 1)}
            disabled={pageIndex === pageCount - 1}
          >
            {'>>'}
          </button>

          {/* Page Size Selector */}
          <select
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Modal Component */}
      <Modal
        isOpen={modalVisible}
        onRequestClose={handleCloseModal}
        contentLabel="Student Modal"
        className="mx-auto my-10 mt-[80px] max-h-[92vh] max-w-full sm:max-w-5xl bg-white rounded-lg shadow-lg overflow-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="sticky top-0 z-10 bg-white px-8 py-1">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold text-center">Enroll Student</h2>
            <div className="text-2xl font-medium bg-gradient-to-r from-blue-500 to-purple-500 text-white px-3 py-1 rounded-lg shadow-md">
  {timer}
</div>

            <button onClick={handleCloseModal}>
              <AiOutlineClose className="w-6 h-6 text-gray-600 hover:text-gray-800 transition duration-200" />
            </button>
          </div>
        </div>
        <form onSubmit={handleSave} className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-3 sm:p-8">
          <div className="flex flex-col">
            <label htmlFor="first_name" className="flex items-center">Student Name<span className='ml-1 text-red-600'>*</span></label>
            <input
              type="text"
              id="student_name"
              name="student_name"
              value={formData.student_name}
              onChange={handleChange}
              className={`p-1 bg-slate-100 border rounded`}
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="gender" className="flex items-center">Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={`p-1 bg-slate-100 border rounded`}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="father_name" className="flex items-center">Father/Guardian Name</label>
            <input
              type="text"
              id="father_name"
              name="father_name"
              value={formData.father_name}
              onChange={handleChange}
              className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="mobile_number" className="flex items-center">Mobile Number 1<span className='ml-1 text-red-600'>*</span></label>
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              value={formData.mobile_number1}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only digits and restrict to 10 digits
                if (/^\d*$/.test(value) && value.length <= 10) {
                  setFormData((prev) => ({
                    ...prev,
                    mobile_number1: value,
                  }));
                }
              }}
                        className="p-1 bg-slate-100 border rounded"
              required
              pattern="\d{10}"
              title="Mobile number must be exactly 10 digits."
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="mobile_number2" className="flex items-center">Mobile Number 2:</label>
            <input
              type="tel"
              id="mobile_number2"
              name="mobile_number2"
              value={formData.mobile_number2}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only digits and restrict to 10 digits
                if (/^\d*$/.test(value) && value.length <= 10) {
                  setFormData((prev) => ({
                    ...prev,
                    mobile_number2: value,
                  }));
                }
              }}
                className="p-1 bg-slate-100 border rounded"
              pattern="\d{10}"
              title="Mobile number must be exactly 10 digits."
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="email" className="flex items-center">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="aadhar_number" className="flex items-center">Aadhar Number:</label>
            <input
              type="text"
              id="aadhar_number"
              name="aadhar_number"
              value={formData.aadhar_number}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/-/g, '');
                const formattedValue = rawValue
                  .replace(/\D/g, '')
                  .replace(/(\d{4})(?=\d)/g, '$1-');

                if (formattedValue.length <= 14) {
                  setFormData((prev) => ({
                    ...prev,
                    aadhar_number: formattedValue,
                  }));
                }
              }}
              className="p-1 bg-slate-100 border rounded"
              pattern="(\d{4}-\d{4}-\d{4})"
              title="Aadhar number must be in the format 1234-5678-9012."
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="state" className="flex items-center">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="district" className="flex items-center">District</label>
            <input
              type="text"
              id="district"
              name="district"
              value={formData.district}
              onChange={handleChange}
                     className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="city" className="flex items-center">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
                  className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="address" className="flex items-center">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
                        className="p-1 bg-slate-100 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="pincode" className="flex items-center">Pincode:</label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={formData.pincode}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only digits and restrict to 6 digits
                if (/^\d*$/.test(value) && value.length <= 6) {
                  setFormData((prev) => ({
                    ...prev,
                    pincode: value,
                  }));
                }
              }}
              className="p-1 bg-slate-100 border rounded"
              pattern="\d{6}"
              title="Pincode must be exactly 6 digits."
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="clg_name" className="flex items-center">College</label>
            <input
              type="text"
              id="clg_name"
              name="clg_name"
              value={formData.clg_name}
              onChange={handleChange}
                         className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="course" className="flex items-center">Course</label>
            <input
              type="text"
              id="course"
              name="course"
              value={formData.course}
              onChange={handleChange}
                className="p-1 bg-slate-100 border rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="inter_percentage" className="flex items-center">Intermediate Percentage/CGPA</label>
            <input
              type="text"
              id="inter_percentage"
              name="inter_percentage"
              value={formData.inter_percentage}
              onChange={(e) => {
                // Only allow numbers, decimals, or percentages
                const value = e.target.value;
                const regex = /^(\d+(\.\d*)?|\.\d+)?$/;  // Regex for decimal or percentage (e.g., 90, 90.5, 75%)
                if (regex.test(value) || value === '') {
                  setFormData((prev) => ({
                    ...prev,
                    inter_percentage: value,
                  }));
                }
              }}
              className="p-1 bg-slate-100 border rounded"
            />
          </div>


          <div className="flex flex-col">
            <label htmlFor="program_type" className="flex items-center">Program Type</label>
            <select
              id="program_type"
              name="program_type"
              value={formData.program_type}
              onChange={handleChange}
              className="p-1 bg-slate-100 border rounded"
            >
              <option value="">Select Program Type</option>
              <option value="UG">UG</option>
              <option value="PG">PG</option>
            </select>
          </div>

          
<div className="flex flex-col">
  <label htmlFor="ug_pg_course" className="flex items-center">UG/PG Course</label>

  {/* Dropdown for selecting courses */}
  <select
    id="ug_pg_course"
    name="ug_pg_course"
    value={dropdownValue}
    onChange={handleSelectChange}
    className="p-1 bg-slate-100 border rounded mb-1"
  >
    <option value="">Select Course</option>
    {courses.map((course) => (
      <option key={course} value={course}>{course}</option>
    ))}
  </select>



  {/* Display selected courses as tags */}
  <div className="flex flex-wrap gap-2">
    {selectedCourses.map((course) => (
      <div key={course} className="flex items-center bg-blue-100 rounded-full px-3 py-1">
        <span>{course}</span>
        <button
          type="button"
          className="ml-2 text-red-500"
          onClick={() => handleRemoveTag(course)}
        >
          <IoIosCloseCircle className='text-blue-800 w-5 h-5'/>
        </button>
      </div>
    ))}
  </div>
  </div>



    
          {/* Call Status Field */}
          <div className="flex flex-col col-span-1 sm:col-span-3">
            <label htmlFor="call_status" className="flex items-center">Call Status<span className='ml-1 text-red-600'>*</span></label>
            <select
              id="call_status"
              name="call_status"
              value={formData.call_status}
              onChange={handleChange}
          className="p-1 bg-slate-100 border rounded"
              required
            >
              <option value="">Select Status</option>
              <option value="Contacted">Contacted</option>
              <option value="Not Answered">Not Answered</option>
            </select>
          </div>

          {/* Remarks Field */}
          <div className="flex flex-col col-span-1 sm:col-span-3">
            <label htmlFor="remarks" className="flex items-center">Remarks:</label>
            <textarea
              id="remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              className="p-2 bg-slate-100 border rounded"
              rows={2}
            />
          </div>

          <div className="flex flex-col col-span-1 sm:col-span-3">
            {/* Existing Radio Buttons */}
            <div className="flex">
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="is_interested"
                  value="Not Interested"
                  checked={formData.is_interested === 'Not Interested'}
                  onChange={handleChange}
                  className="mr-2"
                  disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                />
                Not Interested
              </label>
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="is_interested"
                  value="Interested"
                  checked={formData.is_interested === 'Interested'}
                  onChange={handleChange}
                  className="mr-2"
                  disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                />
                Interested
              </label>
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="is_interested"
                  value="Most Interested"
                  checked={formData.is_interested === 'Most Interested'}
                  onChange={handleChange}
                  className="mr-2"
                  disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                />
                Most Interested
              </label>
              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="is_interested"
                  value="Call Back"
                  checked={formData.is_interested === 'Call Back'}
                  onChange={handleChange}
                  className="mr-2"
                  disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                />
                Call Back
              </label>

              <label className="flex items-center mr-4">
                <input
                  type="radio"
                  name="is_interested"
                  value="Others"
                  checked={formData.is_interested === 'Others'}
                  onChange={handleChange}
                  className="mr-2"
                  disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                />
                Others
              </label>

              
            </div>

            <div className="flex items-center mt-4">


              {/* Display Dropdown when Call Back is selected */}
              {formData.is_interested === 'Call Back' && (
                <div className="mt-2 flex items-center justify-center">
                  <label htmlFor="call_back_reason" className="block mb-2">Call Back Reason<span className='ml-1 text-red-600'>*</span>:</label>
                  <select
                    id="call_back_reason"
                    name="call_back_reason"
                    value={formData.call_back_reason || ''}
                    onChange={handleChange}
                    className="p-2 border ml-4 border-gray-300 rounded"
                    required={formData.is_interested === 'Call Back'} 
                  >
                    <option value="">Select Reason</option>
                    <option value="IPE Exams">IPE Exams</option>
                    <option value="Jee Mains Results">JEE Mains Results</option>
                    <option value="Eamcet Results">EAMCET Results</option>
                    <option value="IPE Results">IPE Results</option>
                    <option value="Supply Exams">Supply Exams</option>
                    <option value="Supply Results">Supply Results</option>
                    <option value="EOD">EOD</option>
                  </select>
                </div>
              )}

                {/* Display Dropdown when 'Others' is selected */}
              {formData.is_interested === 'Others' && (
                <div className="mt-2 flex items-center justify-center">
                  <label htmlFor="other_reasons" className="block mb-2">Other Reason<span className='ml-1 text-red-600'>*</span>:</label>
                  <select
                    id="other_reasons"
                    name="other_reasons"
                    value={formData.other_reasons || ''}
                    onChange={handleChange}
                    className="p-2 border ml-4 border-gray-300 rounded"
                    required={formData.is_interested === 'Others'} 
                  >
                    <option value="">Select Reason</option>
                    <option value="Wrong Number">Wrong Number</option>
                    <option value="Not Reachable">Not Reachable</option>
                    <option value="Number Not Working">Number Not Working</option>
                    <option value="Call Declined">Call Declined</option>
                    <option value="Busy">Busy</option>
                    <option value="Switched Off">Switched Off</option>
                    <option value="Incoming Calls Stopped">Incoming Calls Stopped</option>
                  </select>
                </div>
              )}
            </div>
          </div>



          <div className="flex flex-col col-span-1 sm:col-span-3">
  <button
    type="submit"
    className="bg-blue-600 text-white px-4 py-1 rounded mx-auto w-80"
  >
    Submit
  </button>
</div>



        </form>
      </Modal>
    </div>
  );
};

export default AssignedStudents;
