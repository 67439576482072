import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";

const EnrollStudent = () => {
    const [formData, setFormData] = useState({
        student_name: '',
        gender: '',
        father_name: '',
        mobile_number1: '',
        mobile_number2: '',
        email: '',
        aadhar_number: '',
        state: '',
        district: '',
        city: '',
        address:'',
        pincode: '',
        course:'',
        clg_name:'',
        inter_percentage:'',
        program_type: '',
        ug_pg_course: '',
        call_status: '',
        call_duration: '00:00:00.000000',
        remarks: '',
        is_interested: '', // Default to Yes
        call_back_reason:'',
    });

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate();
    const [selectedCourses, setSelectedCourses] = useState([]); // Stores selected courses
    const [dropdownValue, setDropdownValue] = useState(''); // Value of selected option
    const [timer, setTimer] = useState('00:00:00');
  const [intervalId, setIntervalId] = useState(null);
  const [inTime, setInTime] = useState('');
  const [outTime, setOutTime] = useState('');
    // Courses based on UG/PG selection
    const ugCourses = ['CSE', 'CSE(AI & ML)', 'CSE(AI & DS)', 'AERONAUTICAL ENGINERRING', 'ECE'];
    const pgCourses = [];

    // Filter the courses based on UG/PG selection
    const courses = formData.program_type === 'UG' ? ugCourses : formData.program_type === 'PG' ? pgCourses : [];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        // If call_status is 'Not Answered', reset is_interested field
        if (name === 'call_status' && (value === 'Not Answered' || value === '')) {
          setFormData((prev) => ({
            ...prev,
            is_interested: '', // Clears the value of is_interested
            [name]: value, // Update the call_status
          }));
        } 
        // If 'is_interested' is changed
        else if (name === 'is_interested') {
          // If value is not 'Call Back', clear call_back_reason
          // If value is not 'Others', clear other_reasons
          setFormData((prev) => ({
            ...prev,
            [name]: value, // Update the is_interested value
            ...(value !== 'Call Back' && { call_back_reason: '' }), // Clear call_back_reason if not 'Call Back'
            ...(value !== 'Others' && { other_reasons: '' }), // Clear other_reasons if not 'Others'
          }));
        } else if (name === 'program_type' && (value === 'PG' || value === '')) {
          setSelectedCourses([]);
          setDropdownValue(''); 
          setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
          }));
        } 
        // For other inputs (e.g., checkboxes, radio buttons), just update their values
        else {
          setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value, // Update value for checkbox or other types
          }));
        }
      };  



      const getFormattedISTTime = () => {
        const now = new Date();
      
        // IST Offset in milliseconds (UTC +5:30)
        const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes in milliseconds
        const istTime = new Date(now.getTime() + istOffset);
      
        // Extract year, month, day, hours, minutes, and seconds
        const year = istTime.getUTCFullYear();
        const month = String(istTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(istTime.getUTCDate()).padStart(2, '0');
        const hours = String(istTime.getUTCHours()).padStart(2, '0'); // Use UTC hours
        const minutes = String(istTime.getUTCMinutes()).padStart(2, '0'); // Use UTC minutes
        const seconds = String(istTime.getUTCSeconds()).padStart(2, '0'); // Use UTC seconds
      
        // Return the date and time in yyyy-mm-dd hh:mm:ss format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };



      // Handle dropdown selection
  const handleSelectChange = (e) => {
    const selectedCourse = e.target.value;
    if (selectedCourse && !selectedCourses.includes(selectedCourse)) {
      setSelectedCourses((prev) => [...prev, selectedCourse]); // Add selected course
    }
    setDropdownValue(''); // Clear the dropdown after selection
  };


  useEffect(() => {
    const currentDateTime = getFormattedISTTime(); // Get in-time when the page starts
    setInTime(currentDateTime); // Set in-time
}, []); // Runs only once when the component is mounted

useEffect(() => {
    let seconds = 0;

    const id = setInterval(() => {
      seconds++;
      const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
      const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
      const secs = String(seconds % 60).padStart(2, '0');
      setTimer(`${hours}:${minutes}:${secs}`);
    }, 1000);

    setIntervalId(id);

    return () => clearInterval(id); // Clean up the interval on unmount
  }, []); // Empty dependency array ensures this runs only on component mount

  
  useEffect(() => {
    if (formData.call_duration) {
      // Split the call_duration at the dot to remove milliseconds
      const [timePart] = formData.call_duration.split('.'); 
      const [hours, minutes, seconds] = timePart.split(':').map(part => part.padStart(2, '0')); 
  
      setFormData((prevData) => ({
        ...prevData,
        hours,  
        minutes,  
        seconds,
      }));
    }
  }, [formData.call_duration]);

  
    const validateForm = () => {
      const { call_status, is_interested, call_back_reason, other_reasons } = formData;
    
      if (call_status === 'Contacted') {
        if (!['Not Interested', 'Interested', 'Most Interested', 'Call Back', 'Others'].includes(is_interested)) {
          toast.warning('Please select an option: Not Interested, Interested, Most Interested, Call Back, or Others.');
          return false;
        }
    
        if (is_interested === 'Call Back' && !call_back_reason) {
          toast.warning('Please select a Call Back reason.');
          return false;
        }
    
        if (is_interested === 'Others' && !other_reasons) {
          toast.warning('Please select an Other reason.');
          return false;
        }
      }
    
      return true; // All validations passed
    };
  
  

  const handleRemoveTag = (course) => {
    setSelectedCourses((prev) => prev.filter(c => c !== course));
  };
    const headers = {
        'Authorization': 'Bearer sandeep_university_916',
        'Content-Type': 'application/json',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        if (!validateForm()) {
            setLoadingSubmit(false); 
            return; 
          }                       
        try {
            const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
            if (!encodedEmployeeId) {
                throw new Error('Employee ID not found in session storage');
            }
            const decodedEmployeeId = atob(encodedEmployeeId);
            const apiEndpoint = SummaryApi.uploadStudent.url;
            const method = SummaryApi.uploadStudent.method;
            const payload = {
                employee_student_id: formData.employee_student_id,
                employee_id: decodedEmployeeId, // Adding the decodedEmployeeId to the payload
                student_name: formData.student_name,
                last_name: '',
                gender: formData.gender,
                father_name: formData.father_name,
                mobile_number1: formData.mobile_number1,
                mobile_number2: formData.mobile_number2 || '', // Ensure an empty string if not provided
                email: formData.email || '', // Default to empty string if not provided
                aadhar_number: formData.aadhar_number || '', // Default to empty string if not provided
                state: formData.state,
                district: formData.district,
                city: formData.city,
                address:formData.address,
                pincode: formData.pincode || '',  // Default to empty string if not provided
                course: formData.course, // Assuming this is directly available in formData
                clg_name: formData.clg_name,
                inter_percentage: formData.inter_percentage,
                program_type: formData.program_type,
                ug_pg_course: selectedCourses.join(', '), // Comma-separated string of selected courses
                call_status: formData.call_status || '', // Default to empty string if not provided
                call_duration: timer, 
                remarks: formData.remarks || '', // Default to empty string if not provided
                is_interested: formData.is_interested === "Others" ? formData.other_reasons : formData.is_interested, // If "Others", use other_reasons
                call_back_reason: formData.call_back_reason || '', // Default to empty string if not provided
              };
             
            const response = await fetch(apiEndpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            if (result.status) {
                toast.success('Student added successfully!');
                navigate('/enrolled-students');
                // openWhatsAppMessage(formData, result.ENR_CODE);
                resetForm();


                const studentId = result.student_id; // Extract student_id from the uploadStudent response
        // Now proceed to create the call log
        const currentDateTime = getFormattedISTTime(); // Get system time for out_time
        setOutTime(currentDateTime);
                const callLogPayload = {
                    employee_id: decodedEmployeeId, // Use employee ID as the primary identifier
                    student_id: studentId, // Use the student_id from the result
                    in_time: inTime, // Captured when modal opens
                    out_time: currentDateTime, // Captured when modal closes
                    remarks: formData.remarks || '',
                };
    
                // Send a POST request to log call details
                const callLogResponse = await fetch(SummaryApi.calllogsstudentcreate.url, {
                    method: SummaryApi.calllogsstudentcreate.method, // Should be "POST"
                    headers: {
                        'Authorization': 'Bearer sandeep_university_916',
                        'Content-Type': 'application/json', // Ensure headers indicate JSON data
                    },
                    body: JSON.stringify(callLogPayload), // Convert payload to JSON string
                });
    
                const callLogResult = await callLogResponse.json(); // Parse the response
    
                if (!callLogResult.status) {
                    toast.error(callLogResult.message);
                }
    
            } else {
                toast.error(result.message);
            }
    
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        } finally {
            setLoadingSubmit(false); // Reset loading state when done
        }
    
    };

    const openWhatsAppMessage = (studentData, code) => {
        const message = `🌟 Hello ${studentData.student_name}!.%0A
        We're thrilled to welcome you to SANJAY GHODAWAT UNIVERSITY.%0A
        Your enrollment code is ${code}, and your mobile number is ${studentData.mobile_number1}.%0A
        You’ve chosen the ${studentData.ug_pg_course} course under the ${studentData.program_type} program.%0A
        We're excited about your journey with us, and if you have any questions, don’t hesitate to reach out.%0A
        Looking forward to seeing you soon! 🚀%0A
        Best Regards,%0A
        SANJAY GHODAWAT UNIVERSITY.`;

        const phoneNumber = studentData.mobile_number1; // Ensure this is in international format
        const whatsappURL = `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=${message}`;

        // Open in a new tab
        window.open(whatsappURL, '_blank');
    };

    const resetForm = () => {
        setFormData({
            student_name: '',
            gender: 'Male',
            father_name: '',
            mobile_number1: '',
            mobile_number2: '',
            email: '',
            aadhar_number: '',
            state: '',
            district: '',
            city: '',
            address:'',
            pincode: '',
            program_type: 'UG',
            course: '',
            inter_percentage: '',
            call_status: '',
            call_duration: '00:00:00.000000',
            remarks: '',
            is_interested: '',
            call_back_reason: '',
        });
    };

    return (
        <div>
            <div className='flex justify-between'>
                <h1 className="text-2xl font-bold">Enroll Student</h1>
                <div className="text-2xl font-medium bg-gradient-to-r from-blue-500 to-purple-500 text-white px-3 py-1 rounded-lg shadow-md">
  {timer}
</div>

                <Link to="/enrolled-students">
                    <button className="bg-blue-600 text-white px-4 py-2 rounded-md transition-transform transform hover:scale-105">
                        Enrolled Students
                    </button>
                </Link>
            </div>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 sm:p-8">
                {/* Student Name */}
                <div className="flex flex-col">
                    <label htmlFor="student_name" className="flex items-center">Student Name<span className='ml-1 text-red-600'>*</span></label>
                    <input
                        type="text"
                        id="student_name"
                        name="student_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        required
                    />
                </div>
                {/* Gender */}

                <div className="flex flex-col">
            <label htmlFor="gender" className="flex items-center">Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={`p-1 bg-slate-100 border rounded`}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
                {/* Father Name */}
                <div className="flex flex-col">
                    <label htmlFor="father_name" className="flex items-center">Father/Guardian Name</label>
                    <input
                        type="text"
                        id="father_name"
                        name="father_name"
                        value={formData.father_name}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                    />
                </div>

                {/* Mobile Number 1 */}
                <div className="flex flex-col">
                    <label htmlFor="mobile_number1" className="flex items-center">Mobile Number 1<span className='ml-1 text-red-600'>*</span></label>
                    <input
                        type="tel"
                        id="mobile_number1"
                        name="mobile_number1"
                        value={formData.mobile_number1}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            if (value.length <= 10) {
                                setFormData((prev) => ({
                                    ...prev,
                                    mobile_number1: value,
                                }));
                            }
                        }}
                        className="p-2 bg-slate-100 border rounded"
                        required
                        pattern="\d{10}"
                        title="Mobile number must be exactly 10 digits."
                    />
                </div>

                {/* Mobile Number 2 */}
                <div className="flex flex-col">
                    <label htmlFor="mobile_number2" className="flex items-center">Mobile Number 2:</label>
                    <input
                        type="tel"
                        id="mobile_number2"
                        name="mobile_number2"
                        value={formData.mobile_number2}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            if (value.length <= 10) {
                                setFormData((prev) => ({
                                    ...prev,
                                    mobile_number2: value,
                                }));
                            }
                        }}
                        className="p-2 bg-slate-100 border rounded"
                        pattern="\d{10}"
                        title="Mobile number must be exactly 10 digits."
                    />
                </div>

                {/* Email */}
                <div className="flex flex-col">
                    <label htmlFor="email" className="flex items-center">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"

                    />
                </div>

                {/* Aadhar Number */}
                <div className="flex flex-col">
                    <label htmlFor="aadhar_number" className="flex items-center">Aadhar Number:</label>
                    <input
                        type="text"
                        id="aadhar_number"
                        name="aadhar_number"
                        value={formData.aadhar_number}
                        onChange={(e) => {
                            const rawValue = e.target.value.replace(/-/g, '');
                            const formattedValue = rawValue.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
                            if (formattedValue.length <= 14) {
                                setFormData((prev) => ({
                                    ...prev,
                                    aadhar_number: formattedValue,
                                }));
                            }
                        }}
                        className="p-2 bg-slate-100 border rounded"
                        pattern="(\d{4}-\d{4}-\d{4})"
                        title="Aadhar number must be in the format 1234-5678-9012."
                    />
                </div>

                {/* State */}
                <div className="flex flex-col">
                    <label htmlFor="state" className="flex items-center">State</label>
                    <input
                        type="text"
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        
                    />
                </div>

                {/* District */}
                <div className="flex flex-col">
                    <label htmlFor="district" className="flex items-center">District</label>
                    <input
                        type="text"
                        id="district"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        
                    />
                </div>

                {/* City */}
                <div className="flex flex-col">
                    <label htmlFor="city" className="flex items-center">City:</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"

                    />
                </div>
                {/* Address */}
                <div className="flex flex-col">
                    <label htmlFor="address" className="flex items-center">Address:</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"

                    />
                </div>
                {/* Pincode */}
                <div className="flex flex-col">
                    <label htmlFor="pincode" className="flex items-center">Pincode:</label>
                    <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        value={formData.pincode}
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, '');
                            if (value.length <= 6) {
                                setFormData((prev) => ({
                                    ...prev,
                                    pincode: value,
                                }));
                            }
                        }}
                        className="p-2 bg-slate-100 border rounded"
                        pattern="\d{6}"
                        title="Pincode must be exactly 6 digits."
                    />
                </div>


                <div className="flex flex-col">
                    <label htmlFor="clg_name" className="flex items-center">College</label>
                    <input
                        type="text"
                        id="clg_name"
                        name="clg_name"
                        value={formData.clg_name}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="inter_percentage" className="flex items-center">Intermediate Percentage/CGPA</label>
                    <input
                        type="text"
                        id="inter_percentage"
                        name="inter_percentage"
                        value={formData.inter_percentage}
                        onChange={(e) => {
                            // Only allow numbers, decimals, or percentages
                            const value = e.target.value;
                            const regex = /^(\d+(\.\d*)?|\.\d+)?$/;  // Regex for decimal or percentage (e.g., 90, 90.5, 75%)

                            // If the value matches the regex or is empty, update the state
                            if (regex.test(value) || value === '') {
                                setFormData((prev) => ({
                                    ...prev,
                                    inter_percentage: value,  // Update with valid input
                                }));
                            }
                        }}
                        className="p-2 bg-slate-100 border rounded"
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="course" className="flex items-center">Course</label>
                    <input
                        type="text"
                        id="course"
                        name="course"
                        value={formData.course}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        
                    />
                </div>

                <div className="flex flex-col">
                    <label htmlFor="program_type" className="flex items-center">Program Type</label>
                    <select
                        id="program_type"
                        name="program_type"
                        value={formData.program_type}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        
                    >
                        <option value="">Select Program Type</option>
                        <option value="UG">UG</option>
                        <option value="PG">PG</option>
                    </select>
                </div>

                <div className="flex flex-col">
                    <label htmlFor="ug_pg_course" className="flex items-center">UG/PG Course</label>

                    {/* Dropdown for selecting courses */}
                    <select
                        id="ug_pg_course"
                        name="ug_pg_course"
                        value={dropdownValue}
                        onChange={handleSelectChange}
                        className="p-2 bg-slate-100 border rounded mb-2"
                    >
                        <option value="">Select Course</option>
                        {courses.map((course) => (
                            <option key={course} value={course}>{course}</option>
                        ))}
                    </select>

                    {/* Display selected courses as tags */}
                    <div className="flex flex-wrap gap-2">
                        {selectedCourses.map((course) => (
                            <div key={course} className="flex items-center bg-blue-100 rounded-full px-3 py-1">
                                <span>{course}</span>
                                <button
                                    type="button"
                                    className="ml-2 text-red-500"
                                    onClick={() => handleRemoveTag(course)}
                                >
                                    <IoIosCloseCircle className='text-blue-800 w-5 h-5' />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Call Status Field */}
                <div className="flex flex-col col-span-1 sm:col-span-2">
                    <label htmlFor="call_status" className="flex items-center">Call Status<span className='ml-1 text-red-600'>*</span></label>
                    <select
                        id="call_status"
                        name="call_status"
                        value={formData.call_status}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                        required
                    >
                        <option value="">Select Status</option>
                        <option value="Contacted">Contacted</option>
                        <option value="Not Answered">Not Answered</option>
                    </select>
                </div>


                {/* Remarks */}
                <div className="flex flex-col">
                    <label htmlFor="remarks" className="flex items-center">Remarks:</label>
                    <textarea
                        id="remarks"
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleChange}
                        className="p-2 bg-slate-100 border rounded"
                    />
                </div>

                <div className="flex flex-col col-span-1 sm:col-span-2">
                    {/* Existing Radio Buttons */}
                    <div className="flex">
                        <label className="flex items-center mr-4">
                            <input
                                type="radio"
                                name="is_interested"
                                value="Not Interested"
                                checked={formData.is_interested === 'Not Interested'}
                                onChange={handleChange}
                                className="mr-2"
                                disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                            />
                            Not Interested
                        </label>
                        <label className="flex items-center mr-4">
                            <input
                                type="radio"
                                name="is_interested"
                                value="Interested"
                                checked={formData.is_interested === 'Interested'}
                                onChange={handleChange}
                                className="mr-2"
                                disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                            />
                            Interested
                        </label>
                        <label className="flex items-center mr-4">
                            <input
                                type="radio"
                                name="is_interested"
                                value="Most Interested"
                                checked={formData.is_interested === 'Most Interested'}
                                onChange={handleChange}
                                className="mr-2"
                                disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                            />
                            Most Interested
                        </label>

                        <label className="flex items-center mr-4">
                            <input
                                type="radio"
                                name="is_interested"
                                value="Call Back"
                                checked={formData.is_interested === 'Call Back'}
                                onChange={handleChange}
                                className="mr-2"
                                disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                            />
                            Call Back
                        </label>

                        <label className="flex items-center mr-4">
                            <input
                                type="radio"
                                name="is_interested"
                                value="Others"
                                checked={formData.is_interested === 'Others'}
                                onChange={handleChange}
                                className="mr-2"
                                disabled={formData.call_status === 'Not Answered' || formData.call_status === ''}
                            />
                            Others
                        </label>


                    </div>

                    <div className="flex items-center mt-4">


                        {/* Display Dropdown when Call Back is selected */}
                        {formData.is_interested === 'Call Back' && (
                            <div className="mt-2 flex items-center justify-center">
                                <label htmlFor="call_back_reason" className="block mb-2">Call Back Reason<span className='ml-1 text-red-600'>*</span>:</label>
                                <select
                                    id="call_back_reason"
                                    name="call_back_reason"
                                    value={formData.call_back_reason || ''}
                                    onChange={handleChange}
                                    className="p-2 border ml-4 border-gray-300 rounded"
                                    required={formData.is_interested === 'Call Back'}
                                >
                                    <option value="">Select Reason</option>
                                    <option value="IPE Exams">IPE Exams</option>
                                    <option value="Jee Mains Results">JEE Mains Results</option>
                                    <option value="Eamcet Results">EAMCET Results</option>
                                    <option value="IPE Results">IPE Results</option>
                                    <option value="Supply Exams">Supply Exams</option>
                                    <option value="Supply Results">Supply Results</option>
                                    <option value="EOD">EOD</option>
                                </select>
                            </div>
                        )}

                        {/* Display Dropdown when 'Others' is selected */}
                        {formData.is_interested === 'Others' && (
                            <div className="mt-2 flex items-center justify-center">
                                <label htmlFor="other_reasons" className="block mb-2">Other Reason<span className='ml-1 text-red-600'>*</span>:</label>
                                <select
                                    id="other_reasons"
                                    name="other_reasons"
                                    value={formData.other_reasons || ''}
                                    onChange={handleChange}
                                    className="p-2 border ml-4 border-gray-300 rounded"
                                    required={formData.is_interested === 'Others'}
                                >
                                    <option value="">Select Reason</option>
                                    <option value="Wrong Number">Wrong Number</option>
                                    <option value="Not Reachable">Not Reachable</option>
                                    <option value="Number Not Working">Number Not Working</option>
                                    <option value="Call Declined">Call Declined</option>
                                    <option value="Busy">Busy</option>
                                    <option value="Switched Off">Switched Off</option>
                    <option value="Incoming Calls Stopped">Incoming Calls Stopped</option>
                                </select>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col col-span-1 sm:col-span-2">
                    <button
                        type="submit"
                        className={`bg-blue-600 text-white px-4 py-2 rounded ${loadingSubmit ? 'cursor-not-allowed' : ''}`}
                        disabled={loadingSubmit}
                    >
                        {loadingSubmit ? 'Submitting...' : 'Enroll Student'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EnrollStudent;
