import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import Modal from 'react-modal';



const EmployeeCallLogs = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const [minDate, setMinDate] = useState('');
  useEffect(() => {
    fetchCallLogs();
    const today = new Date().toISOString().split('T')[0];
    setSelectedDate(today);
  }, []);


  const filteredRows = rows.filter((row) => {
    // Date Filter: Matches the selected date or no date is selected
    const dateFilter = selectedDate
      ? new Date(row.created_date).toISOString().split('T')[0] === selectedDate
      : true;
  
    // Search Filter: Matches the search term on employee name (case insensitive)
    const searchFilter = searchTerm
      ? row.employee_name && row.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
  
    // Combine both filters (dateFilter and searchFilter)
    return dateFilter && searchFilter;
  });
  
  const getEmployeeTaskCount = (employeeName) => {
    return filteredRows.filter(
      (row) => row.employee_name === employeeName && new Date(row.created_date).toISOString().split('T')[0] === selectedDate
    ).length;
  };
  

  const uniqueEmployeeRows = Array.from(
    new Set(rows.map((row) => row.employee_name))
  ).map((employeeName) => rows.find((row) => row.employee_name === employeeName));
  
  const fetchCallLogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(SummaryApi.getcalllogsstudent.url, {
        method: SummaryApi.getcalllogsstudent.method,
        headers: {
          'Authorization': 'Bearer sandeep_university_916',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      if (responseData.status && responseData.data.length > 0) {
        setRows(responseData.data);
  
        // Set the minimum date dynamically based on the earliest fetched date
        const earliestDate = responseData.data
          .map(row => new Date(row.created_date))
          .sort((a, b) => a - b)[0]
          .toISOString()
          .split('T')[0];
        setMinDate(earliestDate);
      } else {
        toast.info('No call logs found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch call logs.');
    } finally {
      setLoading(false);
    }
  };

  

  
  const handleDownloadExcel1 = () => {
    if (!selectedEmployee) return; // Prevent export if no employee is selected
  
    // Filter the full data to include only the selected employee and selected date
    const allEmployeeRows = filteredRows.filter(
      row => selectedEmployee && row.employee_name === selectedEmployee.employee_name && 
             new Date(row.created_date).toISOString().split('T')[0] === selectedDate
    );
  
    // If there are no records, create an empty data array with only headers
    if (allEmployeeRows.length === 0) {
      const headers = [
        'S.No', 'Student Name', 'In Time', 'Out Time', 'Remarks'
      ];
      const dataForExcel = [headers];
      const worksheet = XLSX.utils.aoa_to_sheet(dataForExcel); // Use aoa_to_sheet for headers only
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Task Logs');
      
      // Trigger file download with headers only
      XLSX.writeFile(workbook, `${selectedEmployee.employee_name}_Task_Logs_${selectedDate}.xlsx`);
      return;
    }
  
    // Map the filtered data to include the S.No and required fields
    const dataForExcel = allEmployeeRows.map((row, index) => ({
      'S.No': index + 1, // Adding serial number
      'Student Name': row.student_name || 'N/A',
      'In Time': formatTime(row.in_time) || 'N/A',
      'Out Time': formatTime(row.out_time) || 'N/A',
      'Remarks': row.remarks || 'No remarks'
    }));
  
    // Create worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Task Logs');
  
    // Trigger file download
    XLSX.writeFile(workbook, `${selectedEmployee.employee_name}_Task_Logs_${selectedDate}.xlsx`);
  };
  
  
  // Disable the download button if no records exist for the selected employee and date
// Disable the download button if no records exist for the selected employee and date
const isDownloadDisabled = !selectedEmployee || filteredRows.filter(
  row => row.employee_name === selectedEmployee.employee_name &&
         new Date(row.created_date).toISOString().split('T')[0] === selectedDate
).length === 0;

  

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredRows.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(Math.ceil(filteredRows.length / rowsPerPage));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };
  

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    setCurrentPage(1);
  };




  uniqueEmployeeRows.map((row, index) => {
    const taskCount = getEmployeeTaskCount(row.employee_name);
    return (
      <tr key={index}>
        <td className="text-center py-2 text-xs">{row.employee_name || "N/A"}</td>
        <td
          className="text-center py-2 text-xs cursor-pointer hover:bg-gray-200 hover:scale-105 transition-all duration-200"
          onClick={() => handleTaskClick(row)}
        >
          <span className="bg-blue-100 px-2 rounded">
            {taskCount}
          </span>
        </td>
        <td className={`text-center py-2 text-xs ${row.is_active === "1" ? 'text-green-500' : 'text-red-500'}`}>
          {row.is_active === "1" ? "Active" : "Inactive"}
        </td>
      </tr>
    );
  });
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const formatTime = (timeString) => {
    if (timeString === "0000-00-00 00:00:00" || isNaN(new Date(timeString).getTime())) return "N/A";
    return formatDate(timeString);
  };


// Update pagination logic to reflect filtered rows
const paginatedRows = filteredRows.slice(
  (currentPage - 1) * rowsPerPage,
  currentPage * rowsPerPage
);



  const handleTaskClick = (employee) => {
    setSelectedEmployee(employee);
    setModalIsOpen(true);
    setModalCurrentPage(1); // Reset the modal pagination
  };


// Modal code
const modalFilteredRows = selectedEmployee 
  ? rows.filter(row => row.employee_name === selectedEmployee.employee_name && new Date(row.created_date).toISOString().split('T')[0] === selectedDate)
  : [];

const modalPaginatedRows = modalFilteredRows.slice(
  (modalCurrentPage - 1) * rowsPerPage,
  modalCurrentPage * rowsPerPage
);

const handleModalNextPage = () => {
  if (modalFilteredRows.length > 0 && modalCurrentPage < Math.ceil(modalFilteredRows.length / rowsPerPage)) {
    setModalCurrentPage(modalCurrentPage + 1);
  }
};

const handleModalPrevPage = () => {
  if (modalFilteredRows.length > 0 && modalCurrentPage > 1) {
    setModalCurrentPage(modalCurrentPage - 1);
  }
};

const handleModalFirstPage = () => {
  if (modalFilteredRows.length > 0) {
    setModalCurrentPage(1);
  }
};

const handleModalLastPage = () => {
  if (modalFilteredRows.length > 0) {
    setModalCurrentPage(Math.ceil(modalFilteredRows.length / rowsPerPage));
  }
};

const taskCountForSelectedDate = filteredRows.filter(row => row.employee_name === selectedEmployee?.employee_name).length;

const isNoDataAvailable = modalFilteredRows.length === 0;

// Disable all pagination buttons when no data is available
const modalPaginationDisabled = isNoDataAvailable ? true : false;


  return (
    <div className="container mx-auto p-4">
      <header className="py-4 text-center rounded-lg mb-4">
        <h1 className="text-xl font-bold">Employee Call Logs</h1>
        <div className="flex justify-between mt-4">
          <div className="flex gap-4">

    <input
  type="date"
  className="border border-gray-300 rounded-md p-2"
  value={selectedDate}
  onChange={handleDateChange}
  min={minDate} // Set minimum date
  max={new Date().toISOString().split('T')[0]} // Set today's date as maximum
/>
          </div>
          <div className="text-right">
  <p>
    {selectedDate === new Date().toISOString().split('T')[0]
      ? `Today's Employee Count: ${uniqueEmployeeRows.length}`
      : `Employee Count : ${uniqueEmployeeRows.length}`}
  </p>
</div>


        </div>
      </header>



      <table id="call-log-table" className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
        <thead className="bg-gray-100 text-gray-700">
          <tr>
          <th className="text-center py-2">S.NO</th>
            <th className="text-center py-2">Employee Name</th>
            <th className="text-center py-2">Task Count</th>
            <th className="text-center py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="3" className="text-center py-4">Loading...</td>
            </tr>
          ) : uniqueEmployeeRows.length > 0 ? (
            uniqueEmployeeRows.map((row, index) => {
              const taskCount = getEmployeeTaskCount(row.employee_name);
              return (
<tr key={index}>
<td className="text-center py-2 text-xs">{index + 1}</td>  {/* Serial number added here */}

  {/* Employee Name */}
  <td className="text-center py-2 text-xs">{row.employee_name || "N/A"}</td>
  
  {/* Task Count (Clickable) */}
  <td
  className="text-center py-2 text-xs cursor-pointer hover:bg-gray-200 hover:scale-105 transition-all duration-200"
  onClick={() => handleTaskClick(row)}
>
  <span className="bg-blue-100 px-2 rounded">
    {taskCount}
  </span>
</td>


  {/* Active/Inactive Status */}
  <td className={`text-center py-2 text-xs ${row.is_active === "1" ? 'text-green-500' : 'text-red-500'}`}>
    {row.is_active === "1" ? "Active" : "Inactive"}
  </td>
</tr>

              );
            })
          ) : (
            <tr>
              <td colSpan="3" className="text-center py-4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <footer className="text-center py-2 mt-4 rounded-lg">
        <div className="flex items-center justify-between mt-4">
          <div>
            <label htmlFor="rowsPerPage" className="mr-2">Rows per page:</label>
            <select
              id="rowsPerPage"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="border rounded px-2 py-1"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          {/* <div className="flex items-center">
            <button
              onClick={handleFirstPage}
              disabled={currentPage === 1 || filteredRows.length === 0}
              className="border px-3 py-1 rounded mr-2 bg-purple-500 hover:bg-blue-500 disabled:opacity-50 text-white"
            >
              First
            </button>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1 || filteredRows.length === 0}
              className="border px-3 py-1 rounded mr-2 bg-red-500 hover:bg-red-500 disabled:opacity-50 text-white"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {Math.ceil(filteredRows.length / rowsPerPage)}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(filteredRows.length / rowsPerPage) || filteredRows.length === 0}
              className="border px-3 py-1 rounded ml-2 bg-green-500 hover:bg-green-500 disabled:opacity-50 text-white"
            >
              Next
            </button>
            <button
              onClick={handleLastPage}
              disabled={currentPage === Math.ceil(filteredRows.length / rowsPerPage) || filteredRows.length === 0}
              className="border px-3 py-1 rounded ml-2 bg-blue-500 hover:bg-blue-500 disabled:opacity-50 text-white"
            >
              Last
            </button>
          </div> */}
        </div>
      </footer>

      {/* Modal for Employee Task Details */}

      <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  contentLabel="Employee Task Details"
  className="fixed inset-0 bg-white p-10 max-w-3xl w-full mx-auto my-auto z-50 "
  overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  style={{
    content: {
      maxHeight: '80vh', // Limit the height to 80% of the viewport height
      overflowY: 'auto', // Allow vertical scrolling only when content exceeds max height
      border: 'none', // Remove border around the modal
    },
  }}
>
  {/* Header with Download Excel Button and Close Icon */}
  <div className="relative flex justify-between items-center mb-6">
    <p><strong>Task Date : {new Date(selectedDate).toLocaleDateString('en-GB')}</strong></p>

    <div className="absolute left-1/2 transform -translate-x-1/2 text-center mb-4">
      <h2 className="text-xl font-bold">
        {selectedEmployee?.employee_name ? `${selectedEmployee.employee_name}'s Task Details` : 'No Employee Selected'}
      </h2>
    </div>

    <button
      onClick={() => setModalIsOpen(false)}
      className="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
      aria-label="Close"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>

    <button
      onClick={handleDownloadExcel1}
      disabled={isDownloadDisabled} // Disable the button when no records exist
      className={`bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600 absolute top-0 right-12 mt-2 mr-4 text-xs ${isDownloadDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      Download Excel
    </button>
  </div>

  {/* Table Content */}
  <div className="overflow-x-auto mb-4">
    <table className="min-w-full mb-4 rounded-lg shadow-md">
      <thead className="bg-gray-100 text-gray-700">
        <tr>
          <th className="text-center py-2 px-4">S.No</th>
          <th className="text-center py-2 px-4">Student Name</th>
          <th className="text-center py-2 px-4">In Time</th>
          <th className="text-center py-2 px-4">Out Time</th>
          <th className="text-center py-2 px-4">Remarks</th>
        </tr>
      </thead>
      <tbody>
        {modalPaginatedRows.map((row, index) => (
          <tr key={index}>
            <td className="text-center py-2 px-4">{(modalCurrentPage - 1) * rowsPerPage + index + 1}</td>
            <td className="text-center py-2 px-4">{row.student_name || "N/A"}</td>
            <td className="text-center py-2 px-4">{formatTime(row.in_time)}</td>
            <td className="text-center py-2 px-4">{formatTime(row.out_time)}</td>
            <td className="text-center py-2 px-4">{row.remarks || "No remarks"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  {/* Footer with Total Records and Pagination */}
  <div className="flex justify-between items-center mt-4">
    <div className="text-left">
      <span className="text-sm">
        Total Records: {modalFilteredRows.length} | Page {modalCurrentPage} of {Math.ceil(modalFilteredRows.length / rowsPerPage)}
      </span>
    </div>

    <div className="flex items-center">
      <button
        onClick={handleModalFirstPage}
        disabled={modalPaginationDisabled || modalCurrentPage === 1}
        className="border px-3 py-1 rounded mr-2 bg-purple-500 hover:bg-blue-500 disabled:opacity-50 text-white text-xs"
      >
        First
      </button>
      <button
        onClick={handleModalPrevPage}
        disabled={modalPaginationDisabled || modalCurrentPage === 1}
        className="border px-3 py-1 rounded mr-2 bg-red-500 hover:bg-red-500 disabled:opacity-50 text-white text-xs"
      >
        Previous
      </button>
      <button
        onClick={handleModalNextPage}
        disabled={modalPaginationDisabled || modalCurrentPage === Math.ceil(modalFilteredRows.length / rowsPerPage)}
        className="border px-3 py-1 rounded ml-2 bg-green-500 hover:bg-green-500 disabled:opacity-50 text-white text-xs"
      >
        Next
      </button>
      <button
        onClick={handleModalLastPage}
        disabled={modalPaginationDisabled || modalCurrentPage === Math.ceil(modalFilteredRows.length / rowsPerPage)}
        className="border px-3 py-1 rounded ml-2 bg-blue-500 hover:bg-blue-500 disabled:opacity-50 text-white text-xs"
      >
        Last
      </button>
    </div>
  </div>
</Modal>


    </div>
  );
};

export default EmployeeCallLogs;
