import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiOutlineEye } from 'react-icons/ai';
import SummaryApi from '../common';
import { useTable, usePagination } from 'react-table';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    Filler,
} from 'chart.js';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    Filler
);


const EmployeePage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [studentsData, setStudentsData] = useState([]);
    const { employee_id } = useParams();
    const decodedId = atob(employee_id);
    const [callStatusCounts, setCallStatusCounts] = useState({});
    const [dataLimit, setDataLimit] = useState(1000);
    const [totalStudents, setTotalStudents] = useState();
    const [formData, setFormData] = useState({
        student_name: '',
        gender: 'Male',
        father_name: '',
        mobile_number1: '',
        mobile_number2: '',
        email: '',
        aadhar_number: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        course: '',
        program_type: 'Full-time',
        is_interested: false,
        call_status: '', // Add status field
        remarks: '', // Add remarks field
    });

    const [loading, setLoading] = useState(true);
    const [enrollmentData, setEnrollmentData] = useState({});

    const fetchStudents = async () => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.getStudentsByEmployeeID.url, {
                method: SummaryApi.getStudentsByEmployeeID.method,
                headers: {
                    'Authorization': 'Bearer sandeep_university_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ employee_id: decodedId, limit_value: dataLimit }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data from the endpoint');
            }

            const data = await response.json();

            const students = data?.data || []

            const updatedStudentsData = students.map((student, index) => ({
                ...student, // Copy the original student object
                sno: index + 1, // Assign the serial number as index + 1
            }));

            setTotalStudents(data?.total_students)

            setStudentsData(updatedStudentsData || []);

            // Calculate call status counts and enrollment data after setting studentsData
            const calculatedCallStatusCounts = calculateCallStatusCounts(updatedStudentsData || []);
            setCallStatusCounts(calculatedCallStatusCounts);
            calculateEnrollmentData(updatedStudentsData || []);
        } catch (error) {
            toast.error(`Error fetching students: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
    
    
        fetchStudents(dataLimit);
      }, [dataLimit]);

    const downloadExcel = () => {
        const requiredFields = [
            'enrollment_code',
            'student_name',
            'email',
            'mobile_number1',
            'mobile_number2',
            'aadhar_number',
            'state',
            'district',
            'city',
            'pincode',
            'course',
            'clg_name',
            'created_date',
            'call_status',
            'remarks',
            'employee_name'
        ];

        const filteredData = studentsData.map(student => {
            const selectedData = {};
            requiredFields.forEach(field => {
                selectedData[field] = student[field];
            });
            return selectedData;
        });

        // Prepare the worksheet with custom headers
        const headers = {
            enrollment_code: 'Enrollment Code',
            student_name: 'Student Name',
            email: 'Email',
            mobile_number1: 'Mobile Number 1',
            mobile_number2: 'Mobile Number 2',
            aadhar_number: 'Aadhar Number',
            state: 'State',
            district: 'District',
            city: 'City',
            pincode: 'Pincode',
            course: 'Course',
            clg_name: 'College',
            created_date: 'Enrolled Date',
            call_status: 'Call Status',
            remarks: 'Remarks',
            employee_name: 'Employee Name'
        };

        const formattedData = filteredData.map(student => {
            return Object.fromEntries(
                Object.entries(student).map(([key, value]) => [headers[key], value])
            );
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');

        const fileName = `${studentsData[0]?.employee_name}_Student_Enrollment_Data.xlsx`;
        XLSX.writeFile(workbook, fileName);
    };
    const calculateCallStatusCounts = (students) => {
        const counts = {};
        students.forEach(student => {
            const status = student.call_status; // Adjust this based on your actual field name
            counts[status] = (counts[status] || 0) + 1;
        });
        return counts;
    };


    const calculateEnrollmentData = (students) => {
        const enrollments = {};
        const today = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setDate(today.getDate() - 30);

        students.forEach(student => {
            const enrollmentDate = new Date(student.created_date); // Assuming enrollment_date is in a proper date format
            if (enrollmentDate >= oneMonthAgo && enrollmentDate <= today) {
                const dateString = enrollmentDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
                enrollments[dateString] = (enrollments[dateString] || 0) + 1;
            }
        });

        setEnrollmentData(enrollments);
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const openModal = (student) => {
        setFormData(student);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const interestedCount = studentsData.filter(student => student.is_interested === "Interested").length;
    const notInterestedCount = studentsData.filter(student => student.is_interested === "Not Interested").length;
    const mostInterestedCount = studentsData.filter(student => student.is_interested === "Most Interested").length;

    const pieData = {
        labels: ['Interested', 'Not Interested', 'Most Interested'],
        datasets: [{
            data: [interestedCount, notInterestedCount, mostInterestedCount],
            backgroundColor: ['#36A2EB', '#FF6384', '#FF9F40'],
        }],
    };

    const courseCounts = {};
    studentsData.forEach(student => {
        courseCounts[student.course] = (courseCounts[student.course] || 0) + 1;
    });

    // Bar chart data
    const barData = {
        labels: Object.keys(callStatusCounts),
        datasets: [{
            label: 'Call Status Count',
            data: Object.values(callStatusCounts),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        }],
    };

    // Assuming enrollmentData is an object with date keys and enrollment counts as values
    const sortedDates = Object.keys(enrollmentData).sort((a, b) => new Date(a) - new Date(b));

    const sortedData = sortedDates.map(date => enrollmentData[date]);

    const lineData = {
        labels: sortedDates,
        datasets: [{
            label: 'Daily Enrollments',
            data: sortedData,
            fill: true,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            tension: 0.1,
        }],
    };

    const formatDate = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date)) {
            return "NA";
        }

        // Options for formatting the date
        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        // Return the formatted date string
        return date.toLocaleDateString('en-GB', options);
    };


    const columns = React.useMemo(() => [
        { Header: 'SNO', accessor: 'sno' },
        {
            Header: 'Enrollment Code',
            accessor: 'enrollment_code',
        },
        {
            Header: 'Student Name',
            accessor: 'student_name',
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'Mobile Number',
            accessor: 'mobile_number1',
        },
        {
            Header: 'Course',
            accessor: 'course',
        },
        {
            Header: 'Enrolled Date',
            accessor: 'enroll_date',
            Cell: ({ row }) => {
                const { created_date } = row.original;

                return <span>{formatDate(created_date)}</span>;
            },
        },
        {
            Header: 'Status',
            accessor: 'is_interested',
            Cell: ({ value }) => {
                let colorClass = 'text-gray-600'; // Default to red (Not Interested)
                if (value === 'Most Interested') {
                    colorClass = 'text-green-600'; // Green for Most Interested
                } else if (value === 'Interested') {
                    colorClass = 'text-yellow-500'; // Yellow for Interested
                } else if (value === 'Not Interested') {
                    colorClass = 'text-red-500'; // Yellow for Interested
                }

                return (
                    <span className={`font-semibold ${colorClass}`}>
                        {value}
                    </span>
                );
            },

        },
        {
            Header: 'View More',
            accessor: 'view',
            Cell: ({ row }) => (
                <button
                    className="bg-yellow-500 text-white px-3 py-2 rounded-md hover:bg-yellow-600 transition duration-200"
                    onClick={() => openModal(row.original)}
                >
                    <AiOutlineEye className="inline-block mr-1" />
                    View More
                </button>
            ),
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex, pageSize },
        page,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageCount,
    } = useTable({
        columns,
        data: studentsData,
        initialState: { pageIndex: 0, pageSize: 5 },
    }, usePagination);

    return (
        <div className="container mx-auto p-4">
            <div className='flex justify-between mb-6'>
                <h1 className="text-3xl font-bold text-center text-blue-700 capitalize">
                    {studentsData.length === 0 ? 'Loading...' : `${studentsData[0]?.employee_name}'s Student Enrollment Data`}
                </h1>
                {/* <button
                    onClick={downloadExcel}
                    className="bg-green-500 text-white text-lg px-4 py-2 rounded ml-0 md:ml-4"
                >
                    Download Excel Report
                </button> */}
            </div>
            {loading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <div className="overflow-x-auto p-4">

                    <div className='flex justify-end items-baseline mb-4'>
                        <span className="text-xl text-center"><span className='text-red-600 font-bold'>{studentsData.length}</span> of <span className='text-green-500 font-bold'>{totalStudents}</span> Records Loaded</span>
                        <div>
                        {/* <select
                                value={dataLimit}
                                onChange={(e) => setDataLimit(Number(e.target.value))} // Ensure the value is a number
                                className="border rounded px-3 py-2 w-full ml-4"
                            >
                                <option value={0} disabled>Select limit</option>
                                {Math.round(totalStudents / 64 / 1000) * 1000 > 0 && Math.round(totalStudents / 64 / 1000) * 1000 !== 1000 && (
                                    <option value={Math.round(totalStudents / 64 / 1000) * 1000}>
                                        {Math.round(totalStudents / 64 / 1000) * 1000}
                                    </option>
                                )}
                                {Math.round(totalStudents / 32 / 1000) * 1000 > 0 && (
                                    <option value={Math.round(totalStudents / 32 / 1000) * 1000}>
                                        {Math.round(totalStudents / 32 / 1000) * 1000}
                                    </option>
                                )}
                                {Math.round(totalStudents / 16 / 1000) * 1000 > 0 && (
                                    <option value={Math.round(totalStudents / 16 / 1000) * 1000}>
                                        {Math.round(totalStudents / 16 / 1000) * 1000}
                                    </option>
                                )}
                                {Math.round(totalStudents / 8 / 1000) * 1000 > 0 && (
                                    <option value={Math.round(totalStudents / 8 / 1000) * 1000}>
                                        {Math.round(totalStudents / 8 / 1000) * 1000}
                                    </option>
                                )}
                                {Math.round(totalStudents / 4 / 1000) * 1000 > 0 && (
                                    <option value={Math.round(totalStudents / 4 / 1000) * 1000}>
                                        {Math.round(totalStudents / 4 / 1000) * 1000}
                                    </option>
                                )}
                                {Math.round(totalStudents / 2 / 1000) * 1000 > 0 && (
                                    <option value={Math.round(totalStudents / 2 / 1000) * 1000}>
                                        {Math.round(totalStudents / 2 / 1000) * 1000}
                                    </option>
                                )}
                                {totalStudents > 0 && (
                                    <option value={totalStudents}>{totalStudents}</option>
                                )}
                            </select> */}


<select
    value={dataLimit}
    onChange={(e) => setDataLimit(Number(e.target.value))} // Ensure the value is a number
    className="border rounded px-3 py-2 w-full ml-4"
>
    <option value={0} disabled>Select limit</option>
    {(() => {
        const uniqueValues = new Set();

        const addOption = (value) => {
            if (value > 0 && !uniqueValues.has(value)) {
                uniqueValues.add(value);
                return (
                    <option key={value} value={value}>
                        {value}
                    </option>
                );
            }
            return null;
        };

        return [
            addOption(Math.round(totalStudents / 64 / 1000) * 1000),
            addOption(Math.round(totalStudents / 32 / 1000) * 1000),
            addOption(Math.round(totalStudents / 16 / 1000) * 1000),
            addOption(Math.round(totalStudents / 8 / 1000) * 1000),
            addOption(Math.round(totalStudents / 4 / 1000) * 1000),
            addOption(Math.round(totalStudents / 2 / 1000) * 1000),
            totalStudents > 0 && addOption(totalStudents),
        ];
    })()}
</select>











                        </div>

                    </div>
                    <table {...getTableProps()} className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
                        <thead className="bg-gray-200">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()} className="px-4 py-2 text-left text-gray-700 font-semibold">
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className="hover:bg-gray-100 transition duration-200">
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()} className="border px-4 py-2">
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <div className="flex justify-between items-center mb-4">
                        <span>
                            Page {pageIndex + 1} of {pageCount} of {studentsData.length} entries
                        </span>
                        <div>
                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<<"}</button>
                            <button onClick={previousPage} disabled={!canPreviousPage} className="px-3 py-1 border rounded">{"<"}</button>
                            <button onClick={nextPage} disabled={!canNextPage} className="px-3 py-1 border rounded">{">"}</button>
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="px-3 py-1 border rounded">{">>"}</button>
                        </div>
                        <select value={pageSize} onChange={e => {
                            setPageSize(Number(e.target.value));
                        }} className="ml-4 border rounded">
                            {[5, 10, 20, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='flex flex-wrap justify-between'>

                        <div className="my-6 w-full md:w-1/3 p-2">
                            <h2 className="text-xl font-bold text-center text-blue-600">Number of Students by Course</h2>
                            <div className="flex justify-center">
                                <div className="h-96 w-full max-w-2xl">
                                    <Bar data={barData} options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                        },
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="my-6 w-full md:w-1/3 p-2">
                            <h2 className="text-xl font-bold text-center text-blue-600">Daily Enrollments (Last 30 Days)</h2>
                            <div className="flex justify-center">
                                <div className="h-96 w-full max-w-2xl">
                                    <Line data={lineData} options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                        },
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="my-6 w-full md:w-1/3 p-2">
                            <h2 className="text-xl font-bold text-center text-blue-600">Not Interested vs. Interested vs. Most Interested Students</h2>
                            <div className="flex justify-center">
                                <div className="h-96 w-full max-w-md">
                                    <Pie data={pieData} options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                        },
                                    }} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Student Details"
                className="mx-auto flex flex-col my-10 max-h-[80vh] mt-[100px] max-w-[30%] bg-white rounded-lg shadow-lg overflow-auto"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 px-8 py-4">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-semibold text-gray-800">Student Details</h2>
                        <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>
                    </div>
                </div>
                <div className="p-8">
                    {formData && (
                        <div className="space-y-4">
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Enrollment Code:</span>
                                <span className="text-gray-600">{formData.enrollment_code}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Student Name:</span>
                                <span className="text-gray-600">{formData.student_name}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Gender:</span>
                                <span className="text-gray-600">{formData.gender}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Father's Name:</span>
                                <span className="text-gray-600">{formData.father_name}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Mobile Number 1:</span>
                                <span className="text-gray-600">{formData.mobile_number1}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Mobile Number 2:</span>
                                <span className="text-gray-600">{formData.mobile_number2 || 'N/A'}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Email:</span>
                                <span className="text-gray-600">{formData.email}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Aadhar Number:</span>
                                <span className="text-gray-600">{formData.aadhar_number}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">State:</span>
                                <span className="text-gray-600">{formData.state}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">District:</span>
                                <span className="text-gray-600">{formData.district}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">City:</span>
                                <span className="text-gray-600">{formData.city}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Pincode:</span>
                                <span className="text-gray-600">{formData.pincode}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Course:</span>
                                <span className="text-gray-600">{formData.course}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">College:</span>
                                <span className="text-gray-600">{formData.clg_name}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Program Type:</span>
                                <span className="text-gray-600">{formData.program_type}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">UG/PG Course:</span>
                                <span className="text-gray-600">{formData.ug_pg_course}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Call Status:</span>
                                <span className="text-gray-600">{formData.call_status}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Remarks:</span>
                                <span className="text-gray-600">{formData.remarks}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Status:</span>
                                <span className={`text-gray-600 ${formData.is_interested === "1" ? 'text-green-600' : 'text-red-600'}`}>
                                    {formData.is_interested === "1" ? 'Interested' : 'Not Interested'}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default EmployeePage;