import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AiOutlineSearch } from 'react-icons/ai';
import SummaryApi from '../common'; // Import your API configuration
import { useTable } from 'react-table';
import Modal from 'react-modal'; // Import Modal from react-modal

const SearchStudents = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [memberType, setMemberType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const headers = {
    'Authorization': 'Bearer sandeep_university_916',
    'Content-Type': 'application/json',
  };

  const fetchMemberType = () => {
    const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
    const memberType = encodedMemberType ? atob(encodedMemberType) : null;
    setMemberType(memberType);
    return memberType;
  };

  useEffect(() => {
    fetchMemberType();
  }, []);

  const fetchStudents = async (searchTerm, memberType) => {
    setLoading(true);
    try {
      let response;
      if (memberType === 'Admin' || memberType === 'Manager') {
        response = await fetch(SummaryApi.searchstudents.url, {
          method: SummaryApi.searchstudents.method,
          headers,
          body: JSON.stringify({ searchterm: searchTerm }),
        });
      } else {
        const encodedEmployeeId = sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6');
        if (!encodedEmployeeId) {
          throw new Error('Employee ID not found in session storage');
        }
        const decodedEmployeeId = atob(encodedEmployeeId);

        response = await fetch(SummaryApi.searchstudentsById.url, {
          method: SummaryApi.searchstudentsById.method,
          headers,
          body: JSON.stringify({ employee_id: decodedEmployeeId, searchterm: searchTerm }),
        });
      }

      if (!response.ok) {
        throw new Error('Failed to fetch data from the endpoint');
      }

      const data = await response.json();
      const students = data?.data || [];
      const updatedStudentsData = students.map((student, index) => ({
        ...student,
        sno: index + 1,
      }));

      setStudentsData(updatedStudentsData);
      setFilteredData(updatedStudentsData);
    } catch (error) {
      toast.error(`Error fetching students: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Only trigger API call if the search term has at least 3 characters
    if (term.length >= 3) {
      fetchStudents(term, memberType);
    } else {
      setFilteredData([]);
    }
  };

  const handleViewMore = (student) => {
    setSelectedStudent(student);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStudent(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return 'NA';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'SNO',
        Cell: ({ row }) => row.index + 1,
      },
      ...(['Admin', 'Manager'].includes(memberType) ? [
        {
          Header: 'Employee Name',
          accessor: 'employee_name',
        },
      ] : []),
      {
        Header: 'Enrollment Code',
        accessor: 'enrollment_code',
      },
      {
        Header: 'Student Name',
        accessor: 'student_name',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile_number1',
      },
      {
        Header: 'Aadhar Number',
        accessor: 'aadhar_number',
      },
      {
        Header: 'Enrolled Date',
        accessor: 'enroll_date',
        Cell: ({ row }) => {
          const { created_date } = row.original;
          return <span>{formatDate(created_date)}</span>;
        },
      },
      {
        Header: 'Status',
        accessor: 'is_interested',
        Cell: ({ value }) => {
          let colorClass = 'text-gray-600';
          if (value === 'Most Interested') {
            colorClass = 'text-green-600';
          } else if (value === 'Interested') {
            colorClass = 'text-yellow-500';
          } else if (value === 'Not Interested') {
            colorClass = 'text-red-600';
          }
          return (
            <span className={`font-semibold ${colorClass}`}>
              {value}
            </span>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <button onClick={() => handleViewMore(row.original)} className="text-blue-600 hover:underline">
            View More
          </button>
        ),
      },
    ],
    [memberType]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: filteredData,
  });

  Modal.setAppElement('#root');

  return (
    <div className="container mx-auto p-6">
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Fetching Data...</span>
        </div>
      )}
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center">Search Students</h1>
        <input
          type="text"
          placeholder={['Admin', 'Manager'].includes(memberType) ? "Search by student name or mobile number or employee name" : "Search by student name or mobile number"}
          value={searchTerm}
          onChange={handleSearch}
          className="mb-4 flex-grow w-1/2 border mt-4 px-3 py-2 rounded"
        />
        <AiOutlineSearch className="inline-block text-xl ml-[-28px]" />
        {/* <button className="ml-2 bg-blue-600 text-white px-4 py-2 rounded" disabled>
          <AiOutlineSearch className="inline-block mr-1" /> Search
        </button> */}
        <div className="overflow-x-auto p-4">
          <table {...getTableProps()} className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
            <thead className="bg-gray-200">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="px-4 py-2 text-left text-gray-700 font-semibold">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4 text-gray-600">
                    {searchTerm.length < 3
                      ? "Please enter at least 3 characters to search."
                      : `No records found for ${searchTerm}.`}
                  </td>
                </tr>
              ) : (
                rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="hover:bg-gray-100 transition duration-200">
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()} className="border px-4 py-2">
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Student Details"
          className="mx-auto flex flex-col my-10 max-h-[80vh] mt-[100px] max-w-[30%] bg-white rounded-lg shadow-lg overflow-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 px-8 py-4">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-800">Student Details</h2>
              <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">
                &times;
              </button>
            </div>
          </div>
          <div className="p-8">
            {selectedStudent && (
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Enrollment Code:</span>
                  <span className="text-gray-600">{selectedStudent.enrollment_code}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Student Name:</span>
                  <span className="text-gray-600">{selectedStudent.student_name}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Gender:</span>
                  <span className="text-gray-600">{selectedStudent.gender}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Father's Name:</span>
                  <span className="text-gray-600">{selectedStudent.father_name}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Mobile Number 1:</span>
                  <span className="text-gray-600">{selectedStudent.mobile_number1}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Mobile Number 2:</span>
                  <span className="text-gray-600">{selectedStudent.mobile_number2 || 'N/A'}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Email:</span>
                  <span className="text-gray-600">{selectedStudent.email}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Aadhar Number:</span>
                  <span className="text-gray-600">{selectedStudent.aadhar_number}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">State:</span>
                  <span className="text-gray-600">{selectedStudent.state}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">District:</span>
                  <span className="text-gray-600">{selectedStudent.district}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">City:</span>
                  <span className="text-gray-600">{selectedStudent.city}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Pincode:</span>
                  <span className="text-gray-600">{selectedStudent.pincode}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Course:</span>
                  <span className="text-gray-600">{selectedStudent.course}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">College:</span>
                  <span className="text-gray-600">{selectedStudent.clg_name}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Intermediate Percentage/CGPA:</span>
                  <span className="text-gray-600">{selectedStudent.inter_percentage}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Program Type:</span>
                  <span className="text-gray-600">{selectedStudent.program_type}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">UG/PG Course:</span>
                  <span className="text-gray-600">{selectedStudent.ug_pg_course}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Call Status:</span>
                  <span className="text-gray-600">{selectedStudent.call_status}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Remarks:</span>
                  <span className="text-gray-600">{selectedStudent.remarks}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-700">Status:</span>
                  <span
                    className={`${selectedStudent.is_interested === "Most Interested"
                      ? 'font-semibold text-green-600'  // Green for Most Interested
                      : selectedStudent.is_interested === "Interested"
                        ? 'font-semibold text-yellow-500'  // Yellow for Interested
                        : selectedStudent.is_interested === "Not Interested"
                          ? 'font-semibold text-red-600'  // Red for Not Interested
                          : 'text-gray-600'  // Gray for N/A (default)
                      }`}
                  >
                    {selectedStudent.is_interested}
                  </span>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SearchStudents;
