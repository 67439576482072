import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiOutlineUserAdd } from 'react-icons/ai';
import SummaryApi from '../common'; // Import your API configuration
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { elements } from 'chart.js';

const AllEmployees = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editPasswordModalIsOpen, setEditPasswordModalIsOpen] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [memberType, setMemberType] = useState(null);
  const [availableCount, setAvailableCount] = useState(0);

  const [formData, setFormData] = useState({
    member_type: '',
    full_name: '',
    mobile_number: '',
    password: '',
  });
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isActiveConfirmationModalOpen, setIsActiveConfirmationModalOpen] = useState(false); // Modal state
  const [isEmployeeActive, setIsEmployeeActive] = useState(false); // Employee active status
  const [selectedCount, setSelectedCount] = useState(""); // Dropdown value for count
  const navigate = useNavigate();
  const today = new Intl.DateTimeFormat('en-IN', {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date());

  // Create a Date object for the current time in Asia/Kolkata
  const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
  const formatter = new Intl.DateTimeFormat('en-GB', options);

  // Get tomorrow's date in Asia/Kolkata time zone
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Format the date to the desired format (YYYY-MM-DD)
  const maxDate = formatter.format(tomorrow).split('/').reverse().join('-');

  const [selectedDate, setSelectedDate] = useState('');

  const headers = {
    'Authorization': 'Bearer sandeep_university_916',
    'Content-Type': 'application/json',
  };

  const fetchEmployees = async () => {
    try {
      const response = await fetch(SummaryApi.getAllEmployees.url, {
        method: SummaryApi.getAllEmployees.method,
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status && Array.isArray(result.data)) {
        setEmployeesData(result.data);
        setAvailableCount(result.total_available_student_count)
      } else {
        setEmployeesData([]);
      }
    } catch (error) {
      setEmployeesData([]);
      toast.error(`Error fetching employees: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchMemberTypeAndEmployees = async () => {
      setLoading(true);
      try {
        const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
        if (encodedMemberType) {
          const decodedMemberType = atob(encodedMemberType);
          setMemberType(decodedMemberType);
          if (decodedMemberType !== 'Admin' && decodedMemberType !== 'Manager') {
            navigate('/'); // Redirect if not Admin
          }
        }

        await fetchEmployees();
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberTypeAndEmployees();
  }, [navigate]);

  const openModal = () => {
    setFormData({
      member_type: '',
      full_name: '',
      mobile_number: '',
      password: '',
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditPasswordModalIsOpen(false);
    setFormData({
      member_type: '',
      full_name: '',
      mobile_number: '',
      password: '',
    });
    setPasswordData({
      newPassword: '',
      confirmPassword: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const validateMobileNumber = (number) => {
    const mobilePattern = /^\d{10}$/;
    return mobilePattern.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    const requiredFields = ['member_type', 'full_name', 'mobile_number', 'password'];

    for (let field of requiredFields) {
      if (!formData[field]) {
        toast.error(`${field.replace('_', ' ')} is required!`);
        setLoadingSubmit(false);
        return;
      }
    }

    if (!validateMobileNumber(formData.mobile_number)) {
      toast.error('Mobile number must be exactly 10 digits!');
      setLoadingSubmit(false);
      return;
    }

    try {
      const response = await fetch(SummaryApi.uploadEmployee.url, {
        method: SummaryApi.uploadEmployee.method,
        headers: headers,
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      if (responseData.status) {
        toast.success('Employee added successfully!');
        closeModal();
        fetchEmployees();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleEditPassword = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const { newPassword, confirmPassword } = passwordData;

    if (!newPassword || !confirmPassword) {
      toast.error('Both password fields are required!');
      setLoadingSubmit(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match!');
      setLoadingSubmit(false);
      return;
    }

    try {
      const response = await fetch(SummaryApi.changeEmployeePassword.url, {
        method: SummaryApi.changeEmployeePassword.method,
        headers: headers,
        body: JSON.stringify({
          employee_id: selectedEmployee.employee_id,
          password: newPassword,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success('Password updated successfully!');
      closeModal();
      fetchEmployees();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleActivateDeactivate = async () => {
    setLoadingSubmit(true);

    const action = isEmployeeActive === "1" ? 'deactivate' : 'activate';  // Decide whether to activate or deactivate
    const status = isEmployeeActive === "1" ? 0 : 1;  // 1 for activate, 0 for deactivate

    try {
      const response = await fetch(SummaryApi.activateEmployee.url, {
        method: SummaryApi.activateEmployee.method,
        headers: headers,
        body: JSON.stringify({
          employee_id: selectedEmployee.employee_id,
          is_active: status,  // Pass status for activation or deactivation
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success(`Employee ${action}d successfully!`);
      setIsActiveConfirmationModalOpen(false)
      fetchEmployees();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleCheckboxChange = (employeeId) => {
    // Update the selectedEmployeeIds based on checkbox state
    setEmployeesData(prevData =>
      prevData.map(employee => {
        if (employee.employee_id === employeeId) {
          // Toggle the checked status of this employee
          const updatedCheckedStatus = !employee.checked;
          // Update the state of 'checked' for this employee
          return { ...employee, checked: updatedCheckedStatus };
        }
        return employee
      })
    );


  };

  const handleSelectAll = () => {
    // Check if all employees are selected
    const allSelected = employeesData.every(employee => employee.checked);

    // Toggle selection state of all employees
    setEmployeesData(prevData =>
      prevData.map(employee => ({
        ...employee,
        checked: !allSelected, // If all selected, deselect, otherwise select all
      }))
    );
  };


  const handleCountChange = (e) => {
    setSelectedCount(e.target.value);
  };

  const handleBulkActionSubmit = async () => {
    setLoadingSubmit(true);
    // Filter employees that are checked and extract their IDs
    const newlySelectedIds = employeesData
      .filter(employee => employee.checked)  // Get only checked employees
      .map(employee => employee.employee_id);         // Extract their IDs

    if (selectedCount === "") {
      toast.error('Please select count.');
      setLoadingSubmit(false);
      return;
    }

    if (selectedDate === "") {
      toast.error('Please select date.');
      setLoadingSubmit(false);
      return;
    }

    // Check if no employees are selected
    if (newlySelectedIds.length === 0) {
      toast.error('Please select at least one employee.');
      setLoadingSubmit(false);
      return;
    }

    try {
      // Submit the bulk action (POST request)
      const response = await fetch(SummaryApi.bulkAction.url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          employee_ids: newlySelectedIds, // Use newly selected employee IDs
          count: selectedCount,
          created_date: selectedDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.status) {
        toast.success(result.message);
      }
      else{
        toast.error(result.message)
      }

      // Reload employees data after the bulk action (if needed)
      fetchEmployees(); // Assuming this refetches the employee data.

    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
    setLoadingSubmit(false);
  };

  const downloadExcelReport = (studentsData, employee_name) => {
    // The fields we need from each student
    console.log(studentsData);
    const requiredFields = ['student_name', 'mobile_number'];

    // Extract the required fields from the students data
    const filteredData = studentsData.map(student => {
      const selectedData = {};
      requiredFields.forEach(field => {
        selectedData[field] = student[field];
      });
      return selectedData;
    });

    // Prepare the headers for the Excel sheet
    const headers = {
      student_name: 'Student Name',
      mobile_number: 'Mobile Number',
    };

    // Format the data to match the headers
    const formattedData = filteredData.map(student => {
      return Object.fromEntries(
        Object.entries(student).map(([key, value]) => [headers[key], value])
      );
    });

    // Generate the worksheet
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');

    // Create the file name using the employee name and the word "promotion"
    const fileName = `${employee_name.toLowerCase()}_promotionData.xlsx`;

    // Download the Excel file with the new file name
    XLSX.writeFile(workbook, fileName);
  };

  // Usage with your provided data structure
  const handleDownloadClick = (data) => {
    // Extract the students array from the data
    const students = data?.students; // Assuming you want to download students of the first employee
    const employee_name = data?.full_name;

    // Call the download function
    downloadExcelReport(students, employee_name);
  };


  const columns = React.useMemo(
    () => [
      ...(memberType === 'Admin' ? [
        {
          Header: 'Select',
          accessor: 'select', // A dummy column for checkboxes
          Cell: ({ row }) => (
            <input
              type="checkbox"
              checked={row.original.checked}
              onChange={() => handleCheckboxChange(row.original.employee_id)}
            />
          ),
        },
      ] : []),
      {
        Header: 'SNO',
        accessor: 'sno',
      },
      {
        Header: 'Employee Name',
        accessor: 'full_name',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile_number',
      },
      {
        Header: 'Pending Follow-Ups',
        accessor: 'not_follow_up_count',
        Cell: ({ value }) => (
          <span className="text-sm font-medium text-red-500">
            {value}
          </span>
        ),
      },
      {
        Header: 'Student Enrollment Count',
        accessor: 'student_count',
        Cell: ({ row }) => (
          row.original.student_count === "0" ? (
            <span className="text-gray-500">No Student Enrollments</span>
          ) : (
            <button
              className="text-blue-600"
              onClick={() => navigate(`/employee/${btoa(row.original.employee_id)}`)}
            >
              {row.original.student_count}
            </button>
          )
        ),
      },
      {
        Header: 'Today Tasks Count',
        Cell: ({ row }) => (
          <span className="text-sm font-medium text-yellow-600">{
            row.original.today_student_assigned_count}
          </span>
        ),
      },
      {
        Header: 'Tomorrow Tasks Count',
        Cell: ({ row }) => (
          <span className="text-sm font-medium text-green-600">{
            row.original.students.length}
          </span>
        ),
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div>
            <button
              className={`${
                row.original.is_active === "0"
                  ? ' text-blue-300 cursor-not-allowed'  // Disabled style
                  : 'text-blue-700 cursor-pointer'              // Enabled style
              } mr-2 px-4 py-2 rounded-lg`}
              onClick={() => {
                setSelectedEmployee(row.original);
                setEditPasswordModalIsOpen(true);
              }}
              disabled={row.original.is_active === "0"}
            >
              Edit Password
            </button>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ row }) => (
          row.original.is_active === "0" ? (
            <span className="text-red-500">InActive</span>
          ) : (
            <span className="text-green-500">Active</span>
          )
        ),
      },
      ...(memberType === 'Admin' ? [
        {
          Header: 'Edit Status',
          Cell: ({ row }) => (
            <div>
              <button
                className={`bg-${row.original.is_active === "1" ? 'red' : 'green'}-600 text-white px-3 py-1 w-24 rounded-lg`}
                onClick={() => {
                  setSelectedEmployee(row.original);
                  setIsEmployeeActive(row.original.is_active);
                  setIsActiveConfirmationModalOpen(true); // Open confirmation modal
                }}
              >
                {row.original.is_active === "1" ? 'Deactivate' : 'Activate'}
              </button>
            </div>
          ),
        },
        {
          Header: 'Download Excel',
          Cell: ({ row }) => (
            <div>
              <button
                className={`text-white px-3 py-1 w-24 rounded-lg ${row.original.students.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-600'}`}
                onClick={() => {
                  if (row.original.students.length > 0) {
                    handleDownloadClick(row.original); // Pass the entire employee data
                  }
                }}
                disabled={row.original.students.length === 0} // Disable the button if there are no students
              >
                Download
              </button>
            </div>
          ),
        },
      ] : []),
    ],
    [memberType]
  );

  const updatedEmployeesData = employeesData.map((employee, index) => ({
    ...employee, // Copy the original student object
    sno: index + 1, // Assign the serial number as index + 1
  }));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: updatedEmployeesData,
  });

  return (
    <div className="container mx-auto p-6">
      {loading ? (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Fetching Data...</span>
        </div>
      ) : (
        <>
          <div className="container mx-auto p-4">
            {loadingSubmit && (
              <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
                <span className="text-white text-lg">Submitting...</span>
              </div>
            )}
            <div className='flex justify-between'>
              <h1 className="text-3xl mb-6 font-bold text-center">All Employees</h1>
              {memberType === 'Admin' && (
                <button
                  className={`bg-blue-600 text-white text-lg px-4 py-2 rounded mb-4 ${loading ? 'cursor-not-allowed opacity-50' : ''}`}
                  onClick={openModal}
                  disabled={loading}
                >
                  <AiOutlineUserAdd className="inline-block mr-2" />
                  Add Personnel
                </button>
              )}

            </div>

            {memberType === 'Admin' && (
              <div>
                <div className='flex justify-between'>
                  <div>
                    <label>Assign Count: </label>
                    <select value={selectedCount} onChange={handleCountChange} className='bg-blue-500 text-white py-2 px-4 rounded-lg'>
                      <option value=''>Select Count</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={150}>150</option>       
                      <option value={200}>200</option>     
                    </select>
                  </div>
                  <div className='text-lg font-bold bg-slate-100 p-2 rounded-lg'>Available Student Count: <span className='text-red-600'>{availableCount}</span></div>
                </div>

                <div className='my-4'>
                  <label htmlFor="date">Task Assign Date:</label>
                  <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min={maxDate}
                    max={maxDate}
                    className='ml-4 border p-2 rounded-lg'
                    required
                  />
                </div>

                <div className='flex justify-between'>
                  <div className="my-4">
                    <label>Select All: </label>
                    <input
                      type="checkbox"
                      checked={employeesData.every(employee => employee.checked)} // Check if all employees are selected
                      onChange={handleSelectAll}
                    />
                  </div>

                  <div>
                    {/* Submit button for bulk action */}
                    <button onClick={handleBulkActionSubmit} className='bg-blue-500 text-white py-2 px-4 rounded-lg'>
                      Assign Task
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="overflow-x-auto p-4">
              <table {...getTableProps()} className="min-w-full mb-4 rounded-lg shadow-md overflow-hidden">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()} className="bg-gray-200  px-4 py-2 text-left text-gray-700">
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="hover:bg-gray-100">
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()} className="border px-4 py-2">
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Modal for Confirming Activate/Deactivate */}
            <Modal
              isOpen={isActiveConfirmationModalOpen}
              onRequestClose={() => setIsActiveConfirmationModalOpen(false)}
              contentLabel="Activate/Deactivate Employee"
              className="mx-auto my-10 mt-[100px] max-w-xl rounded-lg bg-white p-6 shadow-lg"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
              <h2 className="text-2xl font-semibold mb-4">
                {isEmployeeActive === "1" ? 'Deactivate' : 'Activate'} Employee
                <button className="absolute top-2 right-2" onClick={() => setIsActiveConfirmationModalOpen(false)}>
                  <AiOutlineClose className="text-gray-600" />
                </button>
              </h2>
              <p>Are you sure you want to {isEmployeeActive === "1" ? 'deactivate' : 'activate'} this employee?</p>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleActivateDeactivate}
                  className={`bg-${isEmployeeActive === "1" ? 'red' : 'green'}-600 text-white px-4 py-2 rounded`}
                >
                  {isEmployeeActive === "1" ? 'Deactivate' : 'Activate'}
                </button>
                <button
                  onClick={() => setIsActiveConfirmationModalOpen(false)}
                  className="ml-2 bg-gray-300 text-gray-800 px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </Modal>

            {/* Add Employee Modal */}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Add Personnel"
              className="mx-auto my-10 mt-[100px] max-w-xl rounded-lg bg-white p-6 shadow-lg"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
              <h2 className="text-2xl font-semibold mb-4">
                Add Personnel
                <button className="absolute top-2 right-2" onClick={closeModal}>
                  <AiOutlineClose className="text-gray-600" />
                </button>
              </h2>
              <form onSubmit={handleSubmit}>
                {/* Form fields for employee details */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Member Type</label>
                  <select
                    name="member_type"
                    value={formData.member_type}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  >
                    <option value="" disabled>Select member type</option>
                    <option value="Admin">Admin</option>
                    <option value="Employee">Employee</option>
                    <option value="Consultant">Consultant</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Full Name</label>
                  <input
                    type="text"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Mobile Number</label>
                  <input
                    type="tel"
                    name="mobile_number"
                    value={formData.mobile_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only digits and restrict to 10 digits
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number: value,
                        }));
                      }
                    }}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                    pattern="\d{10}" // Ensures only 10 digits are accepted
                    title="Mobile number must be exactly 10 digits."
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <button type="submit" className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded">
                  Add Employee
                </button>
              </form>
            </Modal>

            {/* Edit Password Modal */}
            <Modal
              isOpen={editPasswordModalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Edit Password"
              className="mx-auto my-10 mt-[100px] max-w-xl rounded-lg bg-white p-6 shadow-lg"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
              <h2 className="text-2xl font-semibold mb-4">
                Edit Password for {selectedEmployee ? selectedEmployee.full_name : ''}
                <button className="absolute top-2 right-2" onClick={closeModal}>
                  <AiOutlineClose className="text-gray-600" />
                </button>
              </h2>
              <form onSubmit={handleEditPassword}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <button type="submit" className={`mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded ${loadingSubmit && 'cursor-not-allowed'}`} disabled={loadingSubmit}>
                  {loadingSubmit ? 'Updating Password...' : 'Update Password'}
                </button>
              </form>
            </Modal>
          </div>
        </>
      )}
    </div>

  );
};

export default AllEmployees;
