import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker'; // Importing DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker
import SummaryApi from '../common';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { FaFileExcel } from 'react-icons/fa'; 
const EmployeeReport = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null); 
  const [endDate, setEndDate] = useState(null); 
  const [filterRange, setFilterRange] = useState('today'); 

  const headers = {
    'Authorization': 'Bearer sandeep_university_916', 
    'Content-Type': 'application/json',
  };

  const isValidAadharDate = (aadhar) => {
    return aadhar && aadhar !== 'N/A';
  };

  const openInNewTab = (
    dataArray,
    columnTitle,
    employeeName = '',
    showEmployeeName = false,
    filterRange = '',
    formatDateRange = () => '',
    startDate = null,
    endDate = null
  ) => {
    const newTab = window.open();
  
    // Initial settings
    // Initial settings
    let recordsPerPage = 10;
    let currentPage = 1;
    const totalRecords = dataArray.length;
  // Function to format call duration in HH:MM:SS format
// Function to format call duration in HH:MM:SS format
// Function to format call duration in HH:MM:SS format, excluding milliseconds
// Function to format call duration in HH:MM:SS format, excluding milliseconds
const formatCallDuration = (duration) => {
  if (!duration) return duration;
  const [hours, minutes, seconds] = duration.split(':');
  return `${hours}:${minutes}:${seconds.split('.')[0]}`; // Trim milliseconds
};



    // Function to render table rows based on the current page and records per page
 // Update the table row generation inside the renderTable function
// Function to render table rows based on the current page and records per page
const renderTable = (page, recordsPerPage) => {
  const startIndex = (page - 1) * recordsPerPage;
  const endIndex = Math.min(page * recordsPerPage, totalRecords);
  const currentRecords = dataArray.slice(startIndex, endIndex);

  return currentRecords.map(student => `
    <tr style="border-bottom: 1px solid #e2e8f0; text-align: center;">
      ${showEmployeeName ? `<td style="padding: 8px 16px;">Employee Name: ${student.employee_name}</td>` : ''}
      <td style="padding: 8px 16px;">${student.enrollment_code}</td>
      <td style="padding: 8px 16px;">${student.student_name}</td>
      <td style="padding: 8px 16px;">${student.aadhar_number}</td>
      <td style="padding: 8px 16px;">${student.address}</td>
      <td style="padding: 8px 16px;">${student.city}</td>
      <td style="padding: 8px 16px;">${student.call_status}</td>
      <td style="padding: 8px 16px;">${student.call_duration}</td>  <!-- Use the formatted call duration -->
      <td style="padding: 8px 16px;">${student.is_interested}</td>
      <td style="padding: 8px 16px;">${student.call_back_reason}</td>
      <td style="padding: 8px 16px;">${student.is_active === '1' ? 'Yes' : 'No'}</td>
      <td style="padding: 8px 16px;">${student.created_date}</td>
    </tr>
  `).join('');
};

    // Function to calculate the total call duration in HH:MM:SS format
    const calculateTotalCallDuration = () => {
      const totalDuration = dataArray.reduce((acc, student) => {
        if (student.call_duration) {
          const [hours, minutes, seconds] = student.call_duration.split(':').map(num => parseInt(num));
          return acc + (hours * 3600 + minutes * 60 + seconds);
        }
        return acc;
      }, 0);
  
      const hours = Math.floor(totalDuration / 3600);
      const minutes = Math.floor((totalDuration % 3600) / 60);
      const seconds = totalDuration % 60;
  
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
  
    const formatDateDisplay = () => {
      if (!filterRange) return '';
    
      const today = new Date();
      let displayRange = '';
    
      switch (filterRange) {
        case 'custom':
          if (startDate && endDate) {
            const start = new Date(startDate).toLocaleDateString('en-GB');
            const end = new Date(endDate).toLocaleDateString('en-GB');
            displayRange = `Custom Range: ${start} - ${end}`;
          } else {
            displayRange = 'Custom Date Range: [Start Date - End Date]';
          }
          break;
    
        case 'today':
          const startToday = new Date(today.setHours(0, 0, 0, 0));
          const endToday = new Date(today.setHours(23, 59, 59, 999));
          displayRange = `Today: ${startToday.toLocaleDateString('en-GB')}`;
          break;
    
        case 'yesterday':
          const startYesterday = new Date(today.setDate(today.getDate() - 1));
          startYesterday.setHours(0, 0, 0, 0);
          const endYesterday = new Date(today);
          endYesterday.setHours(23, 59, 59, 999);
          displayRange = `Yesterday: ${startYesterday.toLocaleDateString('en-GB')}`;
          break;
    
        case 'this_week':
          const firstDayOfWeek = new Date(today);
          firstDayOfWeek.setDate(today.getDate() - today.getDay());
          firstDayOfWeek.setHours(0, 0, 0, 0);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          lastDayOfWeek.setHours(23, 59, 59, 999);
          displayRange = `This Week: ${firstDayOfWeek.toLocaleDateString('en-GB')} - ${lastDayOfWeek.toLocaleDateString('en-GB')}`;
          break;
    
        case 'last_week':
          const lastWeekDate = new Date(today);
          lastWeekDate.setDate(today.getDate() - today.getDay() - 7);
          lastWeekDate.setHours(0, 0, 0, 0);
          const lastWeekEndDate = new Date(lastWeekDate);
          lastWeekEndDate.setDate(lastWeekDate.getDate() + 6);
          lastWeekEndDate.setHours(23, 59, 59, 999);
          displayRange = `Last Week: ${lastWeekDate.toLocaleDateString('en-GB')} - ${lastWeekEndDate.toLocaleDateString('en-GB')}`;
          break;
    
        case 'this_month':
          const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          endOfMonth.setHours(23, 59, 59, 999);
          displayRange = `This Month: ${startOfMonth.toLocaleDateString('en-GB')} - ${endOfMonth.toLocaleDateString('en-GB')}`;
          break;
    
        case 'last_month':
          const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
          endOfLastMonth.setHours(23, 59, 59, 999);
          displayRange = `Last Month: ${startOfLastMonth.toLocaleDateString('en-GB')} - ${endOfLastMonth.toLocaleDateString('en-GB')}`;
          break;
    
        default:
          break;
      }
      return displayRange;
    };
    
  
  
    // HTML structure for the table with pagination, employee name, total call duration, filter, and download options
    const tableHTML = `
      <html>
        <head>
          <title>Students Data</title>
        </head>
        <body style="padding: 24px; background-color: #f7fafc;">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 16px;">
            <div style="flex: 1; text-align: center; font-size: 1.2rem; font-weight: 600;">
              ${columnTitle}
            </div>
            <div style="font-size: 1.2rem; color: green;">
              ${columnTitle}: ${totalRecords}
            </div>
          </div>
          ${employeeName ? `
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 8px;">
              <div style="flex: 1; text-align: right; font-size: 1.2rem; color: green;">
                Total Call Duration: ${calculateTotalCallDuration()}
              </div>
            </div>
          ` : ''}
          <div style="text-align: left; font-size: 1.8rem; font-weight: 600; margin-bottom: 24px;">
            ${employeeName ? `<span style="color:green;">Employee Name: ${employeeName}</span>` : ''}
          </div>
  
          <!-- Date range display -->
          <div class="mb-4">
            <span class="font-semibold text-lg bg-orange-500 rounded-lg text-white p-2">
              ${formatDateDisplay()}  <!-- Display the date range here -->
            </span>
          </div>
  
          <table style="min-width: 100%; table-layout: auto; background-color: #ffffff; border: 1px solid #edf2f7; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); border-collapse: collapse;">
            <thead style="background-color: #e2e8f0;">
              <tr>
                ${showEmployeeName ? '<th style="text-align: center; padding: 8px 16px;">Employee Name</th>' : ''}
                <th style="text-align: center; padding: 8px 16px;">Enrollment Code</th>
                <th style="text-align: center; padding: 8px 16px;">Full Name</th>
                <th style="text-align: center; padding: 8px 16px;">Aadhar Number</th>
                <th style="text-align: center; padding: 8px 16px;">Address</th>
                <th style="text-align: center; padding: 8px 16px;">City</th>
                <th style="text-align: center; padding: 8px 16px;">Call Status</th>
                <th style="text-align: center; padding: 8px 16px;">Call Duration (HH:MM:SS)</th>
                <th style="text-align: center; padding: 8px 16px;">Interested</th>
                <th style="text-align: center; padding: 8px 16px;">Call Back Reason</th>
                <th style="text-align: center; padding: 8px 16px;">Active Status</th>
                <th style="text-align: center; padding: 8px 16px;">Created Date</th>
              </tr>
            </thead>
 <tbody id="table-body">
              ${renderTable(currentPage, recordsPerPage)}
            </tbody>
            <tfoot style="background-color: #f7fafc;">
              <tr>
                <td colspan="11" style="padding: 10px 8px; text-align: center;">
                  <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 16px;">
                    <span>Total Records: ${totalRecords}</span>
                    <div style="text-align: center;">
                      <button id="prev-btn" onclick="changePage('prev')" style="padding: 8px 16px; cursor: pointer;" ${currentPage === 1 ? 'disabled' : ''}>Previous</button>
                      <span id="page-info" style="padding: 8px 16px;">Page ${currentPage} of ${Math.ceil(totalRecords / recordsPerPage)}</span>
                      <button id="next-btn" onclick="changePage('next')" style="padding: 8px 16px; cursor: pointer;" ${currentPage === Math.ceil(totalRecords / recordsPerPage) ? 'disabled' : ''}>Next</button>
                    </div>
<div>
  <select id="records-per-page" onchange="changeRecordsPerPage()" style="padding: 8px 16px; cursor: pointer;">
    <option value="10" ${recordsPerPage === 10 ? 'selected' : ''}>show 10</option>
    <option value="20" ${recordsPerPage === 20 ? 'selected' : ''}>show 20</option>
    <option value="40" ${recordsPerPage === 40 ? 'selected' : ''}>show 40</option>
    <option value="50" ${recordsPerPage === 50 ? 'selected' : ''}>show 50</option>
    <option value="100" ${recordsPerPage === 100 ? 'selected' : ''}>show 100</option>
  </select>
</div>
                </td>
              </tr>
            </tfoot>
          </table>
  <script>
            let currentPage = ${currentPage};
            let recordsPerPage = ${recordsPerPage};
            const totalRecords = ${totalRecords};
  
            const tableBody = document.getElementById('table-body');
            const pageInfo = document.getElementById('page-info');
            const prevBtn = document.getElementById('prev-btn');
            const nextBtn = document.getElementById('next-btn');
  
            function renderTable(page, recordsPerPage) {
              const startIndex = (page - 1) * recordsPerPage;
              const endIndex = Math.min(page * recordsPerPage, totalRecords);
              const currentRecords = ${JSON.stringify(dataArray)}.slice(startIndex, endIndex);
  
              return currentRecords.map(student => \`
                <tr style="border-bottom: 1px solid #e2e8f0; text-align: center;">
                  ${showEmployeeName ? '<td style="padding: 8px 16px;">\${student.employee_name}</td>' : ''}
                  <td style="padding: 8px 16px;">\${student.enrollment_code}</td>
                  <td style="padding: 8px 16px;">\${student.student_name}</td>
                  <td style="padding: 8px 16px;">\${student.aadhar_number}</td>
                  <td style="padding: 8px 16px;">\${student.address}</td>
                  <td style="padding: 8px 16px;">\${student.city}</td>
                  <td style="padding: 8px 16px;">\${student.call_status}</td>
             <td style="padding: 8px 16px;">\${student.call_duration.split('.')[0]}</td>
                  <td style="padding: 8px 16px;">\${student.is_interested}</td>
                  <td style="padding: 8px 16px;">\${student.call_back_reason}</td>
                  <td style="padding: 8px 16px;">\${student.is_active === '1' ? 'Yes' : 'No'}</td>
                  <td style="padding: 8px 16px;">\${student.created_date}</td>
                </tr>
              \`).join('');
            }
  
            function updateTable() {
              tableBody.innerHTML = renderTable(currentPage, recordsPerPage);
              pageInfo.textContent = 'Page ' + currentPage + ' of ' + Math.ceil(totalRecords / recordsPerPage);
              prevBtn.disabled = currentPage === 1;
              nextBtn.disabled = currentPage === Math.ceil(totalRecords / recordsPerPage);
            }
  
            function changePage(direction) {
              if (direction === 'next' && currentPage < Math.ceil(totalRecords / recordsPerPage)) {
                currentPage++;
              } else if (direction === 'prev' && currentPage > 1) {
                currentPage--;
              }
              updateTable();
            }
  
            function changeRecordsPerPage() {
              const recordsPerPageSelect = document.getElementById('records-per-page');
              recordsPerPage = parseInt(recordsPerPageSelect.value);
              currentPage = 1; // Reset to the first page
              updateTable();
            }
  
            updateTable();
          </script>
        </body>
      </html>
    `;
  
    newTab.document.write(tableHTML);
    newTab.document.close();
  
    // Function to change page
    const changePage = direction => {
      if (direction === 'prev' && currentPage > 1) {
        currentPage--;
      } else if (direction === 'next' && currentPage < Math.ceil(totalRecords / recordsPerPage)) {
        currentPage++;
      }
      newTab.document.getElementById('table-body').innerHTML = renderTable(currentPage, recordsPerPage);
      updatePaginationButtons();
    };
  
    // Update pagination buttons' disabled state
    const updatePaginationButtons = () => {
      newTab.document.getElementById('prev-btn').disabled = currentPage === 1;
      newTab.document.getElementById('next-btn').disabled = currentPage === Math.ceil(totalRecords / recordsPerPage);
    };
  
    // Change records per page
    const changeRecordsPerPage = () => {
      recordsPerPage = parseInt(newTab.document.getElementById('records-per-page').value, 10);
      currentPage = 1; // Reset to the first page when records per page is changed
      newTab.document.getElementById('table-body').innerHTML = renderTable(currentPage, recordsPerPage);
      updatePaginationButtons();
    };
  };
  
  
  const fetchEmployeeReport = async () => {
    try {
      const response = await fetch(SummaryApi.employeeReport.url, {
        method: SummaryApi.employeeReport.method,
        headers: headers,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.status && result.data) {
        setEmployeeData(result.data);
      } else {
        toast.error('Failed to fetch employee data.');
      }
    } catch (error) {
      toast.error(`Error fetching employee report: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Trigger fetch on component mount
  useEffect(() => {
    fetchEmployeeReport();
  }, []);

  // Update start and end date based on selected filter range
  useEffect(() => {
    const today = new Date();
    let newStartDate = null;
    let newEndDate = null;

    switch (filterRange) {
      case 'today':
        newStartDate = new Date(today.setHours(0, 0, 0, 0)); // Set to start of today
        newEndDate = new Date(today.setHours(23, 59, 59, 999)); // Set to end of today
        break;
      case 'yesterday':
        newStartDate = new Date(today.setDate(today.getDate() - 1)); // Set to yesterday
        newStartDate.setHours(0, 0, 0, 0);
        newEndDate = new Date(today);
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'this_week':
        const firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - today.getDay()); // Set to first day of this week (Sunday)
        firstDayOfWeek.setHours(0, 0, 0, 0);
        newStartDate = firstDayOfWeek;

        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6); // Last day of this week (Saturday)
        lastDayOfWeek.setHours(23, 59, 59, 999);
        newEndDate = lastDayOfWeek;
        break;
      case 'last_week':
        const lastWeekDate = new Date(today);
        lastWeekDate.setDate(today.getDate() - today.getDay() - 7); // Go to previous week
        lastWeekDate.setHours(0, 0, 0, 0);
        newStartDate = lastWeekDate;

        const lastWeekEndDate = new Date(lastWeekDate);
        lastWeekEndDate.setDate(lastWeekDate.getDate() + 6); // Last day of last week
        lastWeekEndDate.setHours(23, 59, 59, 999);
        newEndDate = lastWeekEndDate;
        break;
      case 'this_month':
        newStartDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of this month
        newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of this month
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'last_month':
        newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1); // First day of last month
        newEndDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of last month
        newEndDate.setHours(23, 59, 59, 999);
        break;
      case 'custom':
        break;
      default:
        break;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [filterRange]); // Re-run when filterRange changes

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sno',
        accessor: 'sno', // Serial number column
      },
      {
        Header: 'Employee Name',
        accessor: 'full_name',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile_number',
      },
      {
        Header: 'Total Calls',
        accessor: 'students_count',
        Cell: ({ value }) => (
          <span
            className={`${value > 0 ? 'bg-gray-400 text-white font-semibold' : 'bg-transparent'
              } px-4 py-2 rounded-md`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Interested Count',
        accessor: 'interested_count',
        Cell: ({ value }) => (
          <span
            className={`${value > 0 ? 'bg-yellow-400 text-white font-semibold' : 'bg-transparent'
              } px-4 py-2 rounded-md`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Most Interested Count',
        accessor: 'most_interested_count',
        Cell: ({ value }) => (
          <span
            className={`${value > 0 ? 'bg-green-500 text-white font-semibold' : 'bg-transparent'
              } px-4 py-2 rounded-md`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Aadhar Count',
        accessor: 'aadhar_count',
      },
      {
        Header: 'Not Answered Calls Count',
        accessor: 'not_answered_count',
        Cell: ({ value }) => (
          <span
            className={`${value > 0 ? 'bg-red-500 text-white font-semibold' : 'bg-transparent'
              } px-4 py-2 rounded-md`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: 'Call Back Count',
        accessor: 'call_back_count',
      },
      {
        Header: 'Not Working Count',
        accessor: 'not_working_count',
      },
    ],
    []
  );


  const handleExportToExcel = () => {
    const currentDate = new Date();
  // Use startDate from the state if available, otherwise default to currentDate (today)
  const fileDate = (startDate || currentDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  // Format the date for worksheet title as "MMM yyyy"
  const formattedDate = (startDate || currentDate).toLocaleDateString('en-GB', {
    month: 'short',
    year: 'numeric',
  });
    const headers = [
      'Sno',
      'Employee Name',
      'Mobile Number',
      'Total Calls',
      'Interested Count',
      'Most Interested Count',
      'Aadhar Count',
      'Not Answered Calls Count',
      'Call Back Count',
      'Not Working Count',
    ];
  
    const exportData = data.map((row) => [
      row.sno,
      row.full_name,
      row.mobile_number,
      row.students_count,
      row.interested_count,
      row.most_interested_count,
      row.aadhar_count,
      row.not_answered_count,
      row.call_back_count,
      row.not_working_count,
    ]);
  
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employee Report');
  
    // Add Title Row (Centered, Bold, Underlined, No Color)
    const titleRow = worksheet.addRow(['OAKYA IT SERVICES PVT LTD;SGU 2025-26']);
    titleRow.font = { 
      bold: true, 
      size: 18,
      underline: true  // Underline the title
    };
    titleRow.alignment = { horizontal: 'center', vertical: 'center' };
    worksheet.mergeCells(`A1:J1`);
  
    // Add Date Row (Bold, Italic, Underlined, No Color)
    const dateRow = worksheet.addRow([`DAILY REPORT - ${formattedDate}`]);
    dateRow.font = { bold: true, italic: true, underline: true, size: 14 }; // Underline added
    dateRow.alignment = { horizontal: 'center', vertical: 'center' };
    worksheet.mergeCells(`A2:J2`);
  
    worksheet.addRow([]); // Empty Row for spacing
  
    // Add Header Row with Bold and Light Blue Background Color
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } }; // Light blue background
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  
    // Add Data Rows and Center Align the Content
    exportData.forEach((row) => {
      const dataRow = worksheet.addRow(row);
      dataRow.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  
    // Add Footer Row for totals (same format as the table)
    const footerRow = worksheet.addRow([
      'Total', '', '',
      totals.students_count, 
      totals.interested_count, 
      totals.most_interested_count, 
      totals.aadhar_count, 
      totals.not_answered_count, 
      totals.call_back_count, 
      totals.not_working_count
    ]);
    footerRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  
    // Adjust column widths to fit content
    worksheet.columns.forEach((column) => {
      column.width = column.header ? column.header.length + 2 : 12;
    });
  
    // Save the workbook as an Excel file with the file name in dd-MMM-yyyy format
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), `Employee_Task_Report_${fileDate}.xlsx`);
    });
  };
  

  
  
  const filterStudentsByDate = (students) => {
    if (startDate && endDate) {
      return students.filter((student) => {
        const studentCallDate = new Date(student.created_date); 
        studentCallDate.setHours(0, 0, 0, 0); 
        return studentCallDate >= startDate && studentCallDate <= endDate; 
      });
    }
    return students;
  };

  // Prepare table data with new calculated fields
  const data = React.useMemo(
    () =>
      employeeData.map((employee, index) => {
        const filteredStudents = filterStudentsByDate(employee.students);
  
        const interestedStudents = filteredStudents.filter(
          (student) => student.is_interested === 'Interested'
        );
        const mostInterestedStudents = filteredStudents.filter(
          (student) => student.is_interested === 'Most Interested'
        );
        const aadharStudents = filteredStudents.filter(
          (student) => isValidAadharDate(student.aadhar_number)
        );
        const notAnsweredStudents = filteredStudents.filter(
          (student) => student.call_status === 'Not Answered'
        );
        const callBackStudents = filteredStudents.filter(
          (student) => student.call_back_reason !== 'N/A'
        );
        const notWorkingStudents = filteredStudents.filter(
          (student) => student.is_interested === 'Not Interested'
        );
  
        return {
          ...employee,
          sno: index + 1, // Serial number starts from 1
          students: filteredStudents, // Array of all students
          interestedStudents, // Array of interested students
          mostInterestedStudents, // Array of most interested students
          aadharStudents, // Array of students with valid Aadhar
          notAnsweredStudents, // Array of students who did not answer
          callBackStudents, // Array of students with a call back reason
          notWorkingStudents, // Array of not interested students
          students_count: filteredStudents.length, // Count of all students
          interested_count: interestedStudents.length, // Count of interested students
          most_interested_count: mostInterestedStudents.length, // Count of most interested students
          aadhar_count: aadharStudents.length, // Count of students with valid Aadhar
          not_answered_count: notAnsweredStudents.length, // Count of students who did not answer
          call_back_count: callBackStudents.length, // Count of students who need a call back
          not_working_count: notWorkingStudents.length, // Count of students who are not interested
        };
      }),
    [employeeData, startDate, endDate, filterRange]
  );
  

// Calculate the totals for each column, including both counts and the actual data
const calculateTotals = () => {
  return data.reduce(
    (totals, employee) => {
      totals.students_count += employee.students_count;
      totals.interested_count += employee.interested_count;
      totals.most_interested_count += employee.most_interested_count;
      totals.aadhar_count += employee.aadhar_count;
      totals.not_answered_count += employee.not_answered_count;
      totals.call_back_count += employee.call_back_count;
      totals.not_working_count += employee.not_working_count;

      // Aggregate student data into arrays for each category
      totals.students.push(...employee.students);
      totals.interested.push(...employee.interestedStudents);
      totals.most_interested.push(...employee.mostInterestedStudents);
      totals.aadhar.push(...employee.aadharStudents);
      totals.not_answered.push(...employee.notAnsweredStudents);
      totals.call_back.push(...employee.callBackStudents);
      totals.not_working.push(...employee.notWorkingStudents);

      return totals;
    },
    {
      students_count: 0,
      interested_count: 0,
      most_interested_count: 0,
      aadhar_count: 0,
      not_answered_count: 0,
      call_back_count: 0,
      not_working_count: 0,
      students: [], // Array of all students
      interested: [], // Array of interested students
      most_interested: [], // Array of most interested students
      aadhar: [], // Array of students with valid Aadhar
      not_answered: [], // Array of students who did not answer
      call_back: [], // Array of students with a call back reason
      not_working: [], // Array of not interested students
    }
  );
};

const totals = React.useMemo(() => calculateTotals(), [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  // Clear all filters
  const clearFilters = () => {
    setFilterRange('');
    setStartDate(null);
    setEndDate(null);
  };

  // Helper function to format date ranges
  const formatDateRange = () => {
    if (filterRange === 'custom' && startDate && endDate) {
      return '';
    }

    let rangeDescription = '';
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Helper function to format date as dd-mm-yyyy
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    switch (filterRange) {
      case 'today':
        rangeDescription = `Today: ${formatDate(today)}`;
        break;
      case 'yesterday':
        const yesterday = new Date(today); // Create a new date object for yesterday
        yesterday.setDate(today.getDate() - 1); // Subtract 1 day to get yesterday's date
        rangeDescription = `Yesterday:  ${formatDate(yesterday)}`;
        break;
      case 'this_week':
        rangeDescription = `This Week:  ${formatDate(start)} to ${formatDate(end)}`;
        break;
      case 'last_week':
        rangeDescription = `Last Week:  ${formatDate(start)} to ${formatDate(end)}`;
        break;
      case 'this_month':
        rangeDescription = `This Month:  ${formatDate(start)} to ${formatDate(end)}`;
        break;
      case 'last_month':
        rangeDescription = `Last Month:  ${formatDate(start)} to ${formatDate(end)}`;
        break;
      default:
        rangeDescription = '';
    }

    return rangeDescription;
  };


  return (
    <div className="container mx-auto p-6">
      {loading ? (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-20 flex justify-center items-center">
          <span className="text-white text-lg">Fetching Data...</span>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-gray-800">Employee Task Report</h1>
            <div className="flex gap-4 items-center">
              <label className="text-gray-700">Select Filter: </label>
              <select
                onChange={(e) => setFilterRange(e.target.value)}
                value={filterRange}
                className="px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">All</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="this_week">This Week</option>
                <option value="last_week">Last Week</option>
                <option value="this_month">This Month</option>
                <option value="last_month">Last Month</option>
                <option value="custom">Custom</option>
              </select>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none"
              >
                Clear Filters
              </button>
            </div>

          </div>
          <button
      onClick={handleExportToExcel}
      className="flex items-center px-3 py-1.5 sm:px-4 sm:py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none transition ease-in-out duration-200 ml-auto"
    >
      <FaFileExcel className="mr-2 text-lg sm:text-xl" /> Download to Excel
    </button>
          {/* Date range display */}
          <div className="mb-4">
            {filterRange && (
              <span
                className={`font-semibold text-lg bg-orange-500 rounded-lg text-white ${filterRange === 'custom' ? '' : 'p-2'}`}
              >
                {formatDateRange()}
              </span>
            )}

          </div>

          {/* Date Picker for start and end date */}
          {filterRange === 'custom' && (
            <div className="flex gap-4 mb-4">
              <div>
                <label className="mr-2 text-gray-700">Start Date: </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  dateFormat="yyyy/MM/dd"
                  maxDate={endDate}
                  isClearable
                  placeholderText="Start Date"
                />
              </div>

              <div>
                <label className="mr-2 text-gray-700">End Date: </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  dateFormat="yyyy/MM/dd"
                  minDate={startDate}
                  isClearable
                  placeholderText="End Date"
                  disabled={!startDate} // Disable until start date is selected
                />
              </div>
            </div>
          )}

          {/* Render Table */}
          <div className="overflow-x-auto shadow-lg rounded-lg">
            <table {...getTableProps()} className="min-w-full table-auto bg-white rounded-lg">
              <thead className="bg-gray-100">
                {headerGroups.map((headerGroup) => (
 <tr {...headerGroup.getHeaderGroupProps()}>
 {headerGroup.headers.map((column) => (
   <th
     {...column.getHeaderProps()}
     className="px-4 py-2 text-left border-b font-semibold text-gray-700"
   >
     {column.render('Header')}
   </th>
 ))}
</tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
  {rows.map((row) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} className="hover:bg-gray-50">
        {row.cells.map((cell) => {
             const zoomEffect = "hover:scale-105 transition-transform duration-150";
                  if (cell.column.id === 'students_count') {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`px-4 py-2 border-b text-gray-600 cursor-pointer font-bold ${zoomEffect}`}
                        onClick={() => openInNewTab(row.original.students, 'TOTAL CALLS', row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  }
          // Add onClick functionality for count columns (if it's one of the count cells)
          if (cell.column.id === 'interested_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.interestedStudents, 'INTERESTED STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }
          if (cell.column.id === 'most_interested_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.mostInterestedStudents, 'MOST INTERESTED STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }
          if (cell.column.id === 'aadhar_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.aadharStudents, 'AADHAR STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }
          if (cell.column.id === 'not_answered_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.notAnsweredStudents, 'NOT ANSWERED STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }
          if (cell.column.id === 'call_back_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.callBackStudents, 'CALL BACK STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }
          if (cell.column.id === 'not_working_count') {
            return (
              <td
                {...cell.getCellProps()}
                className={`px-4 py-2 border-b text-gray-600 cursor-pointer ${zoomEffect}`}
                onClick={() => openInNewTab(row.original.notWorkingStudents, 'NOT WORKING STUDENTS',row.original.full_name,false, filterRange, formatDateRange, startDate, endDate)}
              >
                {cell.render('Cell')}
              </td>
            );
          }

          // Default case for other columns (no click handler)
          return (
            <td {...cell.getCellProps()} className="px-4 py-2 border-b text-gray-600">
              {cell.render('Cell')}
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>
<tfoot>
  <tr>
    <td colSpan={3} className="px-4 py-2 font-bold text-left text-gray-700">Total</td>
    <td
  className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
  onClick={() => openInNewTab(totals.students, 'TOTAL STUDENTS', '', true, filterRange, formatDateRange, startDate, endDate)}
>
  {totals.students_count}
</td>

    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.interested, 'INTERESTED STUDENTS', '', true, filterRange, formatDateRange, startDate, endDate)}
    >
      {totals.interested_count}
    </td>
    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.most_interested, 'MOST INTERESTED STUDENTS', '', true, filterRange, formatDateRange)}
    >
      {totals.most_interested_count}
    </td>
    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.aadhar, 'AADHAR STUDENTS', '', true, filterRange, formatDateRange)}
    >
      {totals.aadhar_count}
    </td>
    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.not_answered, 'NOT ANSWERED STUDENTS', '', true, filterRange, formatDateRange)}
    >
      {totals.not_answered_count}
    </td>
    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.call_back, 'CALL BACK STUDENTS', '', true, filterRange, formatDateRange)}
    >
      {totals.call_back_count}
    </td>
    <td 
      className="px-4 py-2 font-bold text-gray-600 cursor-pointer"
      onClick={() => openInNewTab(totals.not_working, 'NOT WORKING STUDENTS', '', true, filterRange, formatDateRange)}
    >
      {totals.not_working_count}
    </td>
  </tr>
</tfoot>



            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default EmployeeReport;
