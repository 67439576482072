import { createBrowserRouter, redirect } from 'react-router-dom';
import AdminPanel from '../pages/AdminPanel';
import EnrolledStudents from '../pages/EnrolledStudents';
import Dashboard from '../pages/Dashboard';
import EmployeeLogin from '../pages/EmployeeLogin';
import AllEmployees from '../pages/AllEmployees';
import EmployeePage from '../pages/EmployeePage';
import SearchStudents from '../pages/SearchStudents';
import EnrollStudent from '../pages/EnrollStudent';
import AllConsultants from '../pages/AllConsultants';
import ExcelUploadPage from '../pages/ExcelUploadPage';
import AssignedStudents from '../pages/AssignedStudents';
import InterestedStudents from '../pages/InterestedStudents';
import NotInterestedStudents from '../pages/NotInterestedStudents';
import MostInterestedStudents from '../pages/MostInterestedStudents';
import CallBackStudents from '../pages/CallBackStudents';
import NotAnswered from '../pages/NotAnswered';
import EmployeeReport from '../pages/EmployeeReport';
import EmployeeCallLogs from '../pages/EmployeeCallLogs';
// Helper function to check memberType (used in route guards)
const checkUserRole = (requiredRoles) => {
  const encodedMemberType = sessionStorage.getItem('K3M8X1JQ5VZ4B7PWTL9');
  if (encodedMemberType) {
    const decodedMemberType = atob(encodedMemberType);
    // Check if the decoded role is in the required roles array
    if (requiredRoles.includes(decodedMemberType)) {
      return true;
    }
    return false;
  }
  return false;
};

// Router configuration
const router = createBrowserRouter([
  {
    path: "/employee-login",
    element: <EmployeeLogin />,
  },
  {
    path: "/",
    element: <AdminPanel />,
    children: [
      { path: "", element: <Dashboard /> }, // Default route showing Dashboard

      // Protected Routes based on user roles
      {
        path: "enrolled-students",
        element: <EnrolledStudents />,
      },
      {
        path: "interested-students",
        element: <InterestedStudents />,
      },
      {
        path: "not-interested-students",
        element: <NotInterestedStudents />,
      },
      {
        path: "most-interested-students",
        element: <MostInterestedStudents />,
      },
      {
        path: "callback-students",
        element: <CallBackStudents />,
      },
      {
        path: "not-answered-students",
        element: <NotAnswered />,
      },
      {
        path: "today-task",
        element: <AssignedStudents />,
      },
      {
        path: "all-employees",
        element: <AllEmployees />,
        loader: () => {
          if (!checkUserRole(['Admin', 'Manager'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },
      {
        path: "/employee/:employee_id",
        element: <EmployeePage />,
        loader: () => {
          if (!checkUserRole(['Admin', 'Manager'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },
      {
        path: "search-students",
        element: <SearchStudents />,
      },
      {
        path: "enroll-student",
        element: <EnrollStudent />,
        loader: () => {
          if (!checkUserRole(['Employee', 'Consultant'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },
      {
        path: "excel-upload",
        element: <ExcelUploadPage />,
        loader: () => {
          if (!checkUserRole(['Admin'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },
      {
        path: "employee-task-report",
        element: <EmployeeReport />,
        loader: () => {
          if (!checkUserRole(['Admin', 'Manager'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },

      {
        path: "employee-calllogs",
        element: <EmployeeCallLogs />,
        loader: () => {
          if (!checkUserRole(['Admin', 'Manager'])) {
            return redirect('/'); // Redirect if user doesn't have the required role
          }
          return null; // Return null if no further action is needed
        },
      },
    ]
  }
]);

export default router;
